import { colors } from "@mui/material";
import { createTheme } from "@mui/material/styles";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import typography from "./typography";
import shadows from "./shadows";
import fontFace from "./fontFace";

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1180,
      xl: 1400
    }
  },
  palette: {
    background: {
      default: colors.common.white,
      paper: colors.common.white
    },
    brandColor: "#007dba",
    primary: {
      smoke: "#f8fcfd",
      grey2: "#ECF6FA",
      smokeBorder: "#DAE4E9",
      light: "#56798D",
      main: "#003b60",
      dark: "#002238",
      contrastText: colors.common.white
    },
    secondary: {
      main: "#31FEFD",
      contrastText: "#002238"
    },
    text: {
      dark: "#052643",
      primary: "#003b60",
      secondary: "#4C7A8F",
      light: "#c6d2d8",
      grey: "#b4b4b4",
      white: colors.common.white
    },
    info: { main: "#F8F8F8" }
  },
  typography,
  shape: {
    borderRadius: 7,
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: fontFace
    },
    MuiAvatar: {
      styleOverrides: {
        root: {
          fontWeight: "900"
        }
      }
    },
    MuiSelect: {
      defaultProps: {
        size: "small",
        IconComponent: KeyboardArrowDownIcon,
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          fontWeight: "600",
          padding: "0.625rem 1rem"
        }
      },
      defaultProps: {
        disableElevation: true
      }
    },
    MuiBadge: {
      styleOverrides: {
        dot: {
          width: 10,
          height: 10,
          margin: "3px",
          borderRadius: "50%"
        }
      }
    },
    MuiCheckbox: {
      defaultProps: {
        color: "secondary"
      }
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          "&:nth-child(even)": {
            backgroundColor: "#f8fcfd"
          }
        }
      }
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          fontSize: "1rem",
          "&:not(.MuiTableCell-head)": {
            borderBottom: 0,
          },
          "&.MuiTableCell-head": {
            fontWeight: 900,
            paddingBottom: 1,
          }
        }
      }
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          "&.MuiPaper-rounded": {
            borderRadius: 20
          }
        }
      }
    },
    MuiInputLabel: {
      styleOverrides: {
        outlined: {
          overflow: "visible",
          fontStyle: "italic",
          transform: "translate(1.5em, 1em) scale(1)",
          "&.Mui-focused, &.MuiFormLabel-filled": {
            transform: "translate(14px, -9px) scale(0.75)",
            fontStyle: "normal",
          }
        }
      }
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          "&.MuiOutlinedInput-root": {
            borderColor: "#C3D2D9",
            borderRadius: 10,
          }
        },
        input: {
          "&.MuiInputBase-input": {
            padding: "1em 1.5em",
          },
          ".MuiInputBase-input::placeholder": {
            fontWeight: "300",
          },
        }
      }
    },
    MuiToggleButton: {
      styleOverrides: {
        root: {
          fontSize: "12px",
          textTransform: "none",
          whiteSpace: "nowrap",
          "&.Mui-selected": {
            backgroundColor: "#003b60",
            color: "#31FEFD",
            pointerEvents: "none",
          }
        },
      }
    },
    MuiSlider: {
      styleOverrides: {
        root: {
          fontFamily: "inherit"
        }
      }
    }
  },
  shadows: shadows()
});

export default theme;
