import { useState, useEffect } from "react";
import {
  useTheme,
  Button,
  Box,
  Collapse,
  Divider,
  List,
  ListItemButton,
  ListItemText,
  Stack,
  Typography,
} from "@mui/material";
import {
  Clear,
  ExpandMore,
  ExpandLess,
} from "@mui/icons-material";
import { styled, experimental_sx as sx } from "@mui/material/styles";
import SearchInput from "./_SearchInput";
import CustomIcon from "_components/CustomIcon";

export default function SearchEngine({ facets = [], filters, removeFilter, addFilter, initialQuery }) {
  const theme = useTheme();
  const [opened, toggleSidebar] = useState(false);
  const [query, setQuery] = useState(initialQuery ?? "");

  useEffect(() => {
    setQuery(initialQuery ?? "");
  }, [initialQuery]);

  const RemoveButton = styled(Button)(
    sx({
      fontcount: "1.125rem",
      minWidth: 0,
      padding: 0,
      borderRadius: "3px",
      background: "#fff",
      "& > svg": {
        fontcount: "inherit",
      }
    })
  );

  const visibleFacets = facets
    .filter(f => typeof filters[f.technical_field] === "undefined" && f.options.map(o => o.count).some(count => count > 0));

  const Collapsiblefacet = function({ facet }) {
    const [open, setOpen] = useState(true);
    const handleClick = () => setOpen(!open);

    return <>
      <ListItemButton onClick={handleClick}>
        <ListItemText primaryTypographyProps={{ fontWeight: 600, fontSize: "1rem", color: "text.dark" }} primary={facet.functional_field} />
        <Box>{open ? <ExpandLess sx={{ fontSize: "1.1rem" }} /> : <ExpandMore sx={{ fontSize: "1.1rem" }} />}</Box>
      </ListItemButton>
      {
        facet.options.filter(o => o.count > 0).map(option => <Collapse in={open} timeout="auto" key={option.label} unmountOnExit>
          <ListItemButton sx={{ pl: 4, py: "0.125rem" }} disablePadding>
            <ListItemText onClick={() => addFilter(facet.technical_field, option.value)}>
              <Typography component="span" fontSize="0.9rem" color={theme.palette.text.secondary}>{option.label} </Typography>
              <Typography component="span" fontSize="0.9rem" color={theme.palette.secondary.main}>({option.count})</Typography>
            </ListItemText>
          </ListItemButton>
        </Collapse>)
      }
    </>;
  };

  const ResponsiveSidebarStyle = sx({
    backgroundColor: "primary.smoke",
    px: ["24px"],
    py: ["24px"],
    "@media (max-width: 900px)": {
      zIndex: 1,
      maxWidth: 320,
      position: "absolute",
      left: "0",
      top: "30px",
      bottom: "0",
      transition: "all 0.3s ease",
      boxShadow: opened ? "0 0 15px rgba(0, 0, 0, 0.2)" : "none",
      transform: opened ? "none" : "translateX(-98%)",
      backgroundColor: opened ? "primary.smoke" : "primary.dark",
    }
  });

  const ResponsiveButtonStyle = sx({
    position: "absolute",
    right: 0,
    p: "5px",
    minWidth: 0,
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    color: opened ? "primary.main" : "secondary.main",
    transform: "translateX(100%)",
    backgroundColor: opened ? "primary.smoke" : "primary.dark",
    "@media (min-width: 900px)": {
      display: "none",
    }
  });

  return (
    <Box sx={ResponsiveSidebarStyle}>
      <Button sx={ResponsiveButtonStyle} onClick={() => toggleSidebar(!opened)} >
        <CustomIcon name="icon_search" />
      </Button>
      <Box py={3}>
        <SearchInput onSubmit={query => addFilter("query", query)} value={query} onChange={query => setQuery(query)}/>
      </Box>

      {facets.length > 0 && <>
        { Object.keys(filters).length > 0 && <>
          <Divider />
          <Box py={2} px={4}>
            {Object.keys(filters).map(k => <Stack py={1} direction="row" alignItems="center">
              <Typography mr="auto" fontWeight="600" color={theme.palette.primary.light}>
                {facets.find(f => f.technical_field === k).options.find(o => o.value === filters[k]).label}
              </Typography>
              <RemoveButton onClick={() => removeFilter(k)} variant="outlined" color="inherit"><Clear /></RemoveButton>
            </Stack>
            )}
          </Box>
          <Divider />
        </>}

        <List component="ul" sx={{ padding: 2 }} disablePadding>
          {visibleFacets.map(facet => <Collapsiblefacet facet={facet} key={facet.technical_field} />)}
        </List>
      </>
      }
    </Box>
  );
}
