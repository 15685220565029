import { Navigate, Outlet, useLocation } from "react-router-dom";

function ProtectedRoute({ children, redirectPath = "/landing", isAllowed }) {
  const location = useLocation();

  if (!isAllowed) {
    return <Navigate to={redirectPath} replace state={{ from: location }} />;
  }

  // authorized so return component
  return children ? children : <Outlet />;
}

export default ProtectedRoute;
