import { useCallback } from "react";
import {
  useTheme,
  Stack,
  Link,
  Typography,
  Box,
  Fab,
  Container
} from "@mui/material";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { useRecoilValue } from "recoil";
import { Link as RouterLink } from "react-router-dom";

import logoUrl from "_assets/images/eutelsat-logo.svg";
import whiteLogoUrl from "_assets/images/eutelsat-logo-white.svg";
import CustomIcon from "_components/CustomIcon";
import { authAtom } from "_state";

function Footer() {
  const theme = useTheme();
  const auth = useRecoilValue(authAtom);

  const scrollToTop = useCallback(
    () => window.scrollTo({ top: 0, behavior: "smooth" }),
    []
  );

  const style = {
    level1Link: {
      textTransform: "uppercase",
      fontSize: "0.75rem",
      fontWeight: 600,
      color: auth ? theme.palette.primary.contrastText : theme.palette.brandColor,
      [theme.breakpoints.up("md")]: {
        alignSelf: "center"
      }
    },
    websiteLink: {
      textTransform: "uppercase",
      display: "flex",
      alignItems: "center",
      fontSize: "0.75rem",
      fontWeight: 600,
      color: auth ? theme.palette.secondary.main : theme.palette.brandColor
    },
    level2Link: {
      py: 4,
      textTransform: "uppercase",
      fontSize: "0.625rem",
      fontWeight: 600,
      color: theme.palette.text.grey
    },
    container: {
      background: auth ? theme.palette.primary.main : theme.palette.primary.smoke
    }
  };

  return (
    <Box>
      <Box sx={style.container}>
        <Container maxWidth="xl" sx={{ position: "relative", padding: 4 }}>
          <Fab sx={{ position: "absolute", right: "20px", top: "-20px" }} onClick={scrollToTop} size="small" color="secondary">
            <KeyboardArrowUpIcon />
          </Fab>
          <Stack
            direction={{ sm: "column", md: "row" }}
            spacing={{ xs: 1, sm: 2, md: 10 }}
            sx={{ justifyContent: "space-between" }}
          >
            <Stack
              direction={{ sm: "column", md: "row" }}
              spacing={{ xs: 1, sm: 2, md: 5, lg: 10 }}
            >
              <img src={auth ? whiteLogoUrl : logoUrl} alt="eutelsat logo" width={120} />
              { auth && <>
                <Link sx={style.level1Link} to="/catalogue-index" component={RouterLink} underline="hover">Product catalogue</Link>
                <Link sx={style.level1Link} to="/coverage" component={RouterLink} underline="hover">Coverage</Link>
                {/* <Link sx={style.level1Link} to="/services" component={RouterLink} underline="hover">My services</Link> */}
              </>}
            </Stack>
            <Link sx={style.websiteLink} ml={"auto"} href="https://www.eutelsat.com" target="_blank" rel="noopener" underline="hover">
              <CustomIcon name="icon_linkwebsite" width="20px" style={{ marginRight: "5px" }}/>
              Eutelsat Corporate
            </Link>
          </Stack>
        </Container>
      </Box>
      <Container maxWidth="xl" sx={{ position: "relative" }}>
        <Box sx={style.level2Link}>
          <Typography sx={style.level2Link} component="span" mr={2}>Copyright © 2022 Eutelsat Communications SA</Typography>
          <Link sx={style.level2Link} href="https://www.eutelsat.com/fr/home/privacy-policy.html" target="_blank" rel="noopener" underline="hover">Privacy policy</Link>&nbsp;—&nbsp;
          <Link sx={style.level2Link} href="https://www.eutelsat.com/fr/politique-cookies.html" underline="hover" target="_blank" rel="noopener">Cookie Management</Link>&nbsp;—&nbsp;
          <Link sx={style.level2Link} href="https://www.eutelsat.com/fr/home/legal.html" underline="hover" target="_blank" rel="noopener">Legal</Link>
        </Box>
      </Container>
    </Box>
  );
}

export default Footer;
