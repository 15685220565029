import {
  Container,
  Grid,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useRecoilValue } from "recoil";

import { DefaultLayout } from "_layouts";
import { authAtom } from "_state";
import { PageHero, NavLink } from "_components";

export default function Profile() {
  const auth = useRecoilValue(authAtom);

  if (!auth) {
    return null;
  }

  return (
    <DefaultLayout>
      <PageHero mb={4} />
      <Container maxWidth="xl" sx={{ minHeight: "50vh" }}>
        <Typography component="h1" variant="h2" textAlign="center">My account</Typography>
        <Typography component="h2" variant="subtitle1" color="text.secondary" textAlign="center">All information about your account</Typography>

        <Grid container spacing={6} py={4}>
          <Grid item md={2}>
            <NavLink href="#" active>My profile</NavLink>
            {/* <NavLink href="#">Password</NavLink> */}
            {/* <NavLink href="#" sx={{ mb: 8 }}>Email Notifications</NavLink> */}
            {/* <NavLink href="#">Your selection</NavLink> */}
          </Grid>
          <Grid item md={10}>
            <Typography component="h1" variant="h3">My profile</Typography>
            <Typography component="h2" variant="subtitle2" color="text.secondary" mb={3}>Personnal information</Typography>

            <Typography color="primary.light" fontSize="0.875rem" sx={{ marginBottom: 1 }}>
              <Typography sx={{ color: "primary.main", fontSize: "0.875rem", display: "inline-block", minWidth: "6rem" }} component="span" fontWeight="600">Name&nbsp;:</Typography>
              {auth.name}
            </Typography>
            <Typography color="primary.light" fontSize="0.875rem">
              <Typography sx={{ color: "primary.main", fontSize: "0.875rem", display: "inline-block", minWidth: "6rem" }} component="span" fontWeight="600">Surname&nbsp;:</Typography>
              {auth.surname}
            </Typography>

            <Grid container spacing={4} py={4}>
              <Grid item xs={12} md={6}>
                <Stack spacing={2}>
                  <TextField fullWidth variant="outlined" label="CEO" disabled value={auth.job_title || ""}/>
                  <TextField fullWidth variant="outlined" label="Phone number *" disabled value={auth.phone_number || ""}/>
                  <TextField fullWidth variant="outlined" label="E-mail *" disabled value={auth.email_address || ""}/>
                  <TextField fullWidth variant="outlined" label="Company Name *" disabled value={auth.company || ""}/>
                  <TextField fullWidth variant="outlined" label="Company Website" disabled value={auth.company_website || ""}/>
                </Stack>
              </Grid>
              <Grid item xs={12} md={6}>
                <Stack spacing={2}>
                  <TextField fullWidth variant="outlined" label="Country" disabled value={auth.country || ""}/>
                  <TextField fullWidth variant="outlined" label="City" disabled value={auth.city || ""}/>
                  <TextField fullWidth variant="outlined" label="Type of company" disabled value={auth.company_type || ""}/>
                  <TextField fullWidth variant="outlined" label="My interest" disabled value={auth.main_interest_lov || ""}/>
                </Stack>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </DefaultLayout>
  );
}
