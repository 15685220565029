import {
  useTheme,
  Box,
  Button,
  Container,
  Grid,
  Typography,
  Stack,
} from "@mui/material";
import { useState } from "react";

import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";

import Timeline from "./_Timeline";
import FinderCard from "./_FinderCard";
import SolutionFinderResult from "./_SolutionFinderResult";
import Coverage from "./customComponents/Coverage";
import { DefaultLayout } from "_layouts";
import { PageHero } from "_components";
import finderJson from "_helpers/solution-finder";
import { useFinder } from "_helpers";

const customComponents = {
  MKT_Coverage__c: Coverage
};

export default function SolutionFinder() {
  const theme = useTheme();

  const { currentGroupIndex,
    currentGroup,
    currentStepIndex,
    availableValues,
    isLastStep,
    handleNext,
    handlePrev,
    data,
    serializedData,
    updateData,
    handleSubmit, currentStep } = useFinder();

  const [showResults, setShowResults] = useState(false);

  function onSubmit(data) {
    setShowResults(true);
  }

  const displayPrev = currentStepIndex > 0;
  const CustomStep = currentStep.type === "custom" ? customComponents[currentStep.name] : null;

  return (
    <DefaultLayout>
      <PageHero mb={4} />
      <Container >
        <Typography
          mb={4}
          align="center"
          variant="h1"
          component="h1"
          fontSize={"2.625rem"}
          color={theme.palette.text.dark}>
          Solution Finder
        </Typography>

        <Timeline finderJson={finderJson} data={data} currentGroup={currentGroup} currentGroupIndex={currentGroupIndex}/>
      </Container>
      <Container sx={{ minHeight: "50vh" }} maxWidth="xl">
        { showResults
          ? <SolutionFinderResult filters={serializedData} onClose={() => setShowResults(false)}/>
          : <>
            <Grid container spacing={4} alignItems="center" mb={8}>
              <Grid item xs={12} md={4}>
                <Typography
                  component="h2"
                  variant="h1"
                  fontSize={"3rem"}
                  color={theme.palette.text.main}>
                  { currentStep.label }
                </Typography>
              </Grid>
              <Grid item xs={12} md={8}>
                <Stack sx={{ gap: 2, flexFlow: "row wrap" }}>
                  {currentStep.type === "radio"
                    ? availableValues.map(v => <FinderCard
                      key={v.id}
                      value={v.value}
                      label={v.label}
                      icon={v.icon}
                      active={parseInt(data[currentStep.name]) === v.id}
                      updateData={() => updateData(currentStep.name, v.id)}
                    />
                    )
                    : <CustomStep updateData={updateData} data={data} currentStep={currentStep}/>
                  }
                </Stack>
              </Grid>
            </Grid>

            <Box align="right" mb={10}>
              { displayPrev && <Button onClick={handlePrev} variant="contained" color="secondary" size="sm" sx={{ mr: 1 }}><ArrowBackIosIcon fontSize="1rem" sx={{ mr: 1 }}/>Previous</Button>}
              { isLastStep
                ? <Button onClick={() => handleSubmit(onSubmit)} disabled={!data[currentStep.name]} variant="contained" color="secondary" size="sm">
                  Valider
                </Button>
                : <Button onClick={handleNext} disabled={!data[currentStep.name]} variant="contained" color="secondary" size="sm">Next <ArrowForwardIosIcon fontSize="1rem" sx={{ ml: 1 }}/></Button>
              }
            </Box>
          </>
        }

      </Container>
    </DefaultLayout>
  );
}
