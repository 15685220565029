const carouselResponsiveLayout = [
  {
    breakpoint: 0,
    cols: 2,
    gap: 20
  }, {
    breakpoint: 800,
    cols: 3,
    gap: 20
  }, {
    breakpoint: 1024,
    cols: 3,
    gap: 20
  }, {
    breakpoint: 1320,
    cols: 4,
    gap: 30
  }
];

export default carouselResponsiveLayout;
