import {
  Card,
  Box,
} from "@mui/material";
import { styled, experimental_sx as sx } from "@mui/material/styles";

import backgroundImage from "_assets/images/eutelsat-background.jpg";
import whiteLogoUrl from "_assets/images/eutelsat-logo-white.svg";

const BackGround = styled(Box)(
  sx({
    background: `linear-gradient(
      rgba(0, 59, 96, 0.60), 
      rgba(0, 59, 96, 0.60)
    ), url(${backgroundImage})`,
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    width: "100vw",
    height: "100vh"
  })
);

const MessageCard = styled(Card)(
  sx({
    p: 6,
    mx: "auto",
    width: "650px",
    textAlign: "center"
  })
);

export default function RedirectLayout({ children }) {
  return (
    <BackGround>
      <Box component="nav" sx={{ py: 4, textAlign: "center" }}>
        <img src={whiteLogoUrl} alt="eutelsat logo" width={180} />
      </Box>
      <MessageCard>
        {children}
      </MessageCard>
    </BackGround>
  );
}
