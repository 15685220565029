import {
  useTheme,
  Box,
  Button,
  Grid,
  Typography,
  Link
} from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";

import CatalogueEntry from "../catalogue/_CatalogueEntry";
import noResultImg from "_assets/images/no-result.svg";
import CustomIcon from "_components/CustomIcon";
import { useProductActions } from "_actions";

function NoResult() {
  return <Box sx={{ display: "flex", alignItems: "center", flexDirection: "column", mt: 3 }}>
    <img src={noResultImg} alt="" />
    <Typography mt={3} variant="h4">No result found</Typography>
    <Typography mb={3} variant="body2" align="center">We didn’t find what you looked for.<br/>Try again with all searching tools</Typography>
  </Box>;
}

export default function SolutionFinderResult({ onClose, filters }) {
  const theme = useTheme();
  const productActions = useProductActions();
  const { data } = productActions.useProducts(filters);
  const { page_results } = data || {};

  return (
    <Grid container spacing={2} alignItems="top" mb={8}>
      <Grid item lg={3} xs={12}>
        <Typography
          component="h2"
          mb={4}
          pt={4}
          variant="h1"
          fontSize={"2.5rem"}
          color={theme.palette.text.main}>
          We recommend
        </Typography>
        <Button color="secondary" variant="outlined" onClick={onClose}>
          <ArrowBackIosIcon fontSize="1rem" sx={{ mr: 1 }}/>Previous
        </Button>
      </Grid>
      {page_results
      && <Grid item lg={8} xs={12}>
        <Grid container spacing={2} alignItems="center" mb={8}>
          {page_results.length > 0
            ? <>
              {page_results.map(p => <Grid item key={p.ProductCode} xs={12} sm={3}>
                <CatalogueEntry product={p} display="card" />
              </Grid>
              )}
              <Grid item xs={12} sm={3} sx={{ ml: "auto" }}>
                <Box sx={{ display: "flex", alignItems: "center", flexDirection: "column" }} mb={5}>
                  <Box sx={{ mb: "10px" }}>
                    <CustomIcon name="icon_productcatalogue" height="45" width="45"/>
                  </Box>
                  <Button component={RouterLink} to="/catalogue" color="secondary" variant="contained">Back to Catalogue</Button>
                </Box>
                <Box sx={{ display: "flex", alignItems: "center", flexDirection: "column" }}>
                  <Box sx={{ mb: "10px" }}>
                    <CustomIcon name="icon_coverage" height="45" width="45"/>
                  </Box>
                  <Button component={RouterLink} to="/coverage" variant="contained" color="info">Go to coverage Map</Button>
                </Box>
              </Grid>
            </>
            : <>
              <NoResult/>
              <Grid item xs={12} sm={3} sx={{ ml: "auto" }}>
                <Box sx={{ display: "flex", alignItems: "center", flexDirection: "column" }} mb={5}>
                  <Box sx={{ mb: "10px" }}>
                    <CustomIcon name="icon_search" height="45" width="45"/>
                  </Box>
                  <Link href="/solution-finder" underline="none">
                    <Button color="secondary" variant="contained" >Search again</Button>
                  </Link>
                </Box>
                <Box sx={{ display: "flex", alignItems: "center", flexDirection: "column" }}>
                  <CustomIcon name="icon_contact" height="45" width="45"/>
                  <Button component={RouterLink} to="/contact" variant="contained" color="secondary">Contact Eutelsat</Button>
                </Box>
              </Grid>
            </>
          }

        </Grid>

      </Grid>
      }
    </Grid>
  );
}
