import {
  Box,
  Typography,
  Stack,
  Avatar
} from "@mui/material";
import { styled, experimental_sx as sx } from "@mui/material/styles";
import CheckIcon from "@mui/icons-material/Check";
import CustomIcon from "_components/CustomIcon";

const Tag = ({ label }) => <Box sx={{ display: "flex", alignItems: "center" }}>
  <Avatar sx={{ bgcolor: "secondary.main", width: 10, height: 10, mr: "3px" }}>
    <CheckIcon sx={{ fontSize: 8, color: "primary.main" }}/>
  </Avatar><Typography variant="body4">{label}</Typography>
</Box>;

export default function Timeline({ finderJson, currentGroup, data, currentGroupIndex }) {
  const Steps = styled(Stack)(
    sx({
      position: "relative",
      "&::before": {
        content: "\"\"",
        position: "absolute",
        zIndex: "-1",
        borderTop: "5px dotted",
        borderColor: "secondary.main",
        width: "100%",
        top: "38px"
      }
    })
  );

  const StepCircle = styled(Stack)(
    sx({
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      mb: 2,
      width: 75,
      height: 75,
      borderRadius: "50%",
      border: 2,
      backgroundColor: "primary.grey2",
      borderColor: "primary.light",
      color: "primary.light",
      "&.is-active": {
        border: 0,
        backgroundColor: "secondary.main",
        color: "primary.dark",
      },
      "&.is-valid": {
        border: 0,
        backgroundColor: "primary.main",
        color: "primary.contrastText",
      }
    })
  );

  const Tags = styled(Box)(
    sx({
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      gap: 1,
      flexWrap: "wrap"
    })
  );

  return (
    <Steps spacing={2} justifyContent="space-around" direction="row" mb={10}>
      {finderJson.groups.map((g, index) => <Box key={g.id} sx={{ width: (100 / finderJson.groups.length) + "%" }}align="center">
        <StepCircle
          className={
            `${currentGroup.id === g.id ? "is-active" : ""}
                 ${index < currentGroupIndex ? "is-valid" : ""}
                `
          }
        >
          {g.name === "business_group" && <CustomIcon name="icon_graph" width="45" height="45"/>}
          {g.name === "application_group" && <CustomIcon name="icon_application" width="45" height="45"/>}
          {g.name === "implementation_group" && <CustomIcon name="icon_implement" width="45" height="45"/>}
          {g.name === "coverage_group" && <CustomIcon name="icon_coverage" width="45" height="45"/>}
        </StepCircle>
        <Typography variant="subtitle1" mb={1}>{g.label}</Typography>
        <Tags>
          {g.step_ids.map((step_id, index) => {
            const step = finderJson.steps.find(s => s.id === step_id);

            const label = step.type === "custom"
              ? data[step.name]
              : finderJson.values.find(v => v.id === parseInt(data[step.name]))?.label;

            return label ? <Tag key={index} label={label}/> : null;
          })}
        </Tags>
      </Box>)}
    </Steps>
  );
}
