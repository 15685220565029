import {
  Box,
  Typography,
  Container
} from "@mui/material";
import { experimental_sx as sx } from "@mui/material/styles";
import CircleIcon from "@mui/icons-material/Circle";

const bulletListStyle = isBulletList => sx({
  columns: isBulletList ? "2  auto" : "auto auto"
});

export default function BenefitsList({ benefits }) {
  const isBulletList = !benefits[0].Name;

  return (
    <Container maxWidth={isBulletList ? "lg" : "lg"} sx={bulletListStyle(isBulletList)}>
      {benefits.map((benefit, index) => <Box sx={{ mb: 2 }} key={index}>
        {benefit.Name
          ? <Box pl={4}>
            <Typography fontWeight="600" color="primary.dark" sx={{ mb: 0.5, textTransform: "uppercase" }}>{benefit.Name}</Typography>
            <Typography variant="body2" color="text.secondary">{benefit.Description}</Typography>
          </Box>
          : <Box sx={{ display: "flex", alignItems: "center" }}>
            <CircleIcon sx={{ fontSize: 8, mr: 1 }}/><Typography fontWeight="600" color="primary.dark">{benefit.Description}</Typography>
          </Box>
        }

      </Box>
      )}
    </Container>
  );
}
