import {
  useTheme,
  Button,
  Grid,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import { styled, experimental_sx as sx } from "@mui/material/styles";
import CustomIcon from "_components/CustomIcon";

export default function FinderPaper(props) {
  const theme = useTheme();

  const SmokePaper = styled(Paper)(
    sx({
      mb: 4,
      px: 3,
      py: 2,
      background: theme.palette.primary.smoke,
      border: `1px solid ${theme.palette.text.light}`,
      borderRadius: 3,
      boxShadow: "none"
    })
  );

  return <SmokePaper>
    <Grid container spacing={[2, 2, 4]} alignItems="center">
      <Grid item lg={4} sm={12}>
        <Stack direction="row" spacing={4} alignItems="center">
          <CustomIcon name="icon_finder" width="65" stroke={theme.palette.secondary.main} />
          <Typography variant="h3">Solution Finder</Typography>
        </Stack>
      </Grid>
      <Grid item lg={5} sm={8}>
        <Typography variant="subtitle2" color={theme.palette.primary.light}>Start solving your business challenges with our easy to use Solution Finder tool.</Typography>
      </Grid>
      <Grid item lg={3} sm={4} textAlign="right">
        <Button variant="contained" color="secondary" size="sm" to="/solution-finder" component={RouterLink}>Let's go</Button>
      </Grid>
    </Grid>
  </SmokePaper>;
}
