import {
  Button,
  Container,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useTheme,
} from "@mui/material";
import { DefaultLayout } from "_layouts";
import { PageHero, NavLink } from "_components";

export default function Services() {
  const theme = useTheme();

  const fakeSubscriptions = [
    {
      id: "877984",
      business: "Connectivity",
      name: "advance VNO",
      kind: "Maritime",
    }, {
      id: "546432",
      business: "Video",
      name: "CORE MHZ",
      kind: "Entreprise",
    }
  ];

  return (
    <DefaultLayout>
      <PageHero mb={4} />
      <Container maxWidth="xl" sx={{ minHeight: "50vh" }}>
        <Typography align="center" variant="h1" component="h1" fontSize={"2.625rem"} color={theme.palette.text.dark}>My services</Typography>
        <Typography align="center" variant="subtitle1" color={theme.palette.text.secondary}>Manage all your subscriptions</Typography>

        <Grid container spacing={6} py={4}>
          <Grid item md={2}>
            <NavLink href="#" active>My Contracts</NavLink>
          </Grid>
          <Grid item md={10}>
            <Typography variant="h3" mb={4} sx={{ fontSize: "1.75rem" }}>Active subscriptions</Typography>

            <TableContainer>
              <Table aria-label="Active subscriptions table">
                <TableHead>
                  <TableRow>
                    <TableCell>Contract ID</TableCell>
                    <TableCell>Business</TableCell>
                    <TableCell>Service Name</TableCell>
                    <TableCell>Service type</TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {fakeSubscriptions.map(row => <TableRow key={fakeSubscriptions.id}>
                    <TableCell component="th" scope="row">
                      {row.id}
                    </TableCell>
                    <TableCell>{row.business}</TableCell>
                    <TableCell>{row.name}</TableCell>
                    <TableCell>{row.kind}</TableCell>
                    <TableCell>
                      <Button variant="contained" color="secondary">Go to portal</Button>
                    </TableCell>
                  </TableRow>)}
                </TableBody>
              </Table>
            </TableContainer>

          </Grid>
        </Grid>
      </Container>
    </DefaultLayout>
  );
}
