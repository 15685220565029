import { styled, experimental_sx as sx } from "@mui/material/styles";
import { Clear } from "@mui/icons-material";
import {
  TextField,
  InputAdornment,
  IconButton,
  Button,
} from "@mui/material";
import CustomIcon from "_components/CustomIcon";

const CustomTextField = styled(TextField)(
  sx({
    ".MuiInputBase-input": {
      px: [1, 1, 2, 3],
      py: [1, 1, 2, 2],
    },
    ".MuiInputBase-input::placeholder": {
      fontStyle: "italic",
    },
    ".MuiInputBase-root": {
      borderColor: "primary.main",
      p: 1,
      paddingRight: 0,
      borderRadius: 2,
    },
    "&.Mui-focused": {
      fontStyle: "normal"
    }
  })
);

export default function SearchInput(props) {
  const { value, onChange, onSubmit } = props;

  function reset() {
    onChange("");
  }

  return <CustomTextField
    fullWidth
    placeholder="Type your text"
    value={value}
    onKeyPress={ev => {
      if (ev.key === "Enter") {
        onSubmit(value);
        ev.preventDefault();
      }
    }}
    InputProps={{
      endAdornment:
      <InputAdornment position="end" sx={{ borderLeft: 1 }}>
        <Button
          onClick={reset}
          sx={{ borderRight: 1, borderRadius: 0, color: "primary.light", p: "0.25em", minWidth: 0 }}
        >
          <Clear width="24" height="24" />
        </Button>
        <IconButton onClick={() => onSubmit(value)} sx={{ marginRight: 1 }}>
          <CustomIcon name="icon_search" width="24" />
        </IconButton>
      </InputAdornment>
    }}
    {...props}
    onChange={({ target: { value } }) => onChange(value)}
  />;
}
