import {
  useTheme,
  Button,
  Card,
  Container,
  Grid,
  Stack,
  Select,
  MenuItem,
  Typography,
  FormGroup,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormLabel,
} from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import { styled, experimental_sx as sx } from "@mui/material/styles";
import { useState } from "react";

import SearchInput from "./_SearchInput";
import { DefaultLayout } from "_layouts";
import { PageHero } from "_components";
import CustomIcon from "_components/CustomIcon";
import { history } from "_helpers";

const ColCard = styled(Card)(
  sx({
    p: 4,
    borderRadius: 2,
    backgroundColor: "primary.smoke",
    height: "100%",
    textAlign: "center",
  })
);

export default function CatalogueIndex() {
  const theme = useTheme();
  const [query, setQuery] = useState("");
  const [business, setBusiness] = useState("Connectivity");
  const [market, setMarket] = useState("");
  const catalogueRouteParams = `/catalogue?MKT_Business__c=${business}&MKT_Market__c=${market}`;

  return (
    <DefaultLayout>
      <PageHero mb={4} />

      <Container sx={{ minHeight: "60vh", pb: 10 }} maxWidth="xl">
        <Typography
          align="center"
          variant="h1"
          component="h1"
          fontSize={"2.625rem"}
          color={theme.palette.text.dark}
          mb={1}>
          Discover our catalogue
        </Typography>
        <Typography
          align="center"
          variant="subtitle1"
          color={theme.palette.text.secondary}
          mb={6}>
          Discover our Managed Services for Internet Connectivity
        </Typography>

        <Grid container spacing={4}>
          <Grid item xs={12} sm={6} lg={4}>
            <ColCard elevation={12} sx={{ height: "100%" }}>
              <Stack component="form" spacing={2} alignItems="center" sx={{ height: "100%" }}>
                <Typography component="h2" variant="h3" fontSize="1.625rem" mb={4}>Search</Typography>
                <SearchInput
                  value={query}
                  onChange={query => setQuery(query)}
                  onSubmit={query => history.push("/catalogue?query=" + query)}
                />
                <Button
                  variant="contained"
                  color="secondary"
                  size="sm"
                  to={"/catalogue" + (query !== "" ? `?query=${query}` : "")}
                  component={RouterLink}>
                  Launch search
                </Button>
              </Stack>
            </ColCard>
          </Grid>
          <Grid item xs={12} sm={6} lg={5}>
            <ColCard elevation={12} sx={{ height: "100%" }}>
              <Typography component="h2" variant="h3" fontSize="1.625rem" mb={4}>Choose a Category</Typography>
              <FormGroup row sx={{ alignItems: "center", gap: 1, mb: 2 }}>
                <FormLabel>What are you looking for&nbsp;?</FormLabel>
                <RadioGroup
                  row
                  name="row-radio-buttons-group"
                  value={business}
                  onChange={e => setBusiness(e.target.value)}
                >
                  <FormControlLabel control={<Radio />} value="Connectivity" label="Internet" />
                  <FormControlLabel control={<Radio />} value="Broadcast" label="Video" />
                </RadioGroup>
              </FormGroup>
              <FormGroup row sx={{ alignItems: "center", gap: 1, mb: 2 }}>
                <FormLabel id="app-select">For which application&nbsp;?</FormLabel>
                <Select
                  labelId="app-select"
                  value={market}
                  label="Martitime"
                  sx={{ flex: "1 0 auto" }}
                  onChange={e => setMarket(e.target.value)}
                >
                  <MenuItem value="Maritime">Maritime</MenuItem>
                  <MenuItem value="Aviation">Aviation</MenuItem>
                  <MenuItem value="Government">Government</MenuItem>
                  <MenuItem value="Enterprise">Enterprise</MenuItem>
                  <MenuItem value="Consumer">Consumer</MenuItem>
                  <MenuItem value="Backhaul">Backhaul</MenuItem>
                </Select>
              </FormGroup>
              <Button
                variant="outlined"
                color="primary"
                disabled={!market || !business}
                size="sm"
                to={catalogueRouteParams}
                component={RouterLink}>
                Launch search
              </Button>
            </ColCard>
          </Grid>
          <Grid item xs={12} sm={12} lg={3}>
            <ColCard elevation={12} sx={{ height: "100%" }}>
              <Stack spacing={2} alignItems="center" sx={{ height: "100%" }}>
                <Typography variant="h3" fontSize="1.625rem">Solution Finder</Typography>
                <CustomIcon name="icon_finder" width="75" stroke={theme.palette.secondary.main} />
                <Typography variant="subtitle2" color={theme.palette.primary.light}>Start solving your business challenges with our easy to use Solution Finder tool.</Typography>
                <Button
                  variant="contained"
                  color="secondary"
                  size="sm"
                  to="/solution-finder"
                  component={RouterLink}>
                  Let's go
                </Button>
              </Stack>
            </ColCard>
          </Grid>
        </Grid>
      </Container>
    </DefaultLayout>
  );
}
