import {
  Box,
  Stack,
  Typography,
} from "@mui/material";
import { styled, experimental_sx as sx } from "@mui/material/styles";

import pdfIconUrl from "_assets/icons/icon_filepdf.svg";

export default function DocumentCard(props) {
  const { doc } = props;

  const entryStyle = sx({
    display: "flex",
    p: 2,
  });

  const Entry = styled(Box)(entryStyle);

  return <Entry sx={{ fontSize: "1rem", ...props.sx }}>
    <Box mr={2}
      sx={{
        flex: "0 0 100px",
        flexDirection: "column",
        p: 2,
        backgroundColor: "primary.dark",
        color: "secondary.main",
        borderRadius: 1,
        justifyContent: "space-evenly",
        display: "flex",
        alignItems: "center"
      }}
    >
      <img src={pdfIconUrl} alt="pdf icon" />
      <Typography sx={{ color: "primary.smoke", fontWeight: "900", fontSize: "1.125rem", textTransform: "uppercase", mt: 1 }}>{doc.Type}</Typography>
      <Typography sx={{ color: "secondary.main", fontSize: "0.875rem" }}>{doc.Size}</Typography>
    </Box>
    <Stack textAlign="left">
      <Typography textTransform="uppercase" variant="h3" fontSize="1.25em" color="text.primary" mb={0.5}>
        { doc.Name }
      </Typography>
      <Typography color="text.secondary" fontWeight="700" fontSize="1.125rem" paragraph mb={0.5}>
        { doc.Family }
      </Typography>
      <Typography color="text.secondary" fontSize="1.125rem" paragraph mb={0.5}>
        { doc.Description }
      </Typography>
    </Stack>
  </Entry>;
}
