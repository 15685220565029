import {
  Box,
  Typography,
} from "@mui/material";
import { styled, experimental_sx as sx } from "@mui/material/styles";

import CustomIcon from "_components/CustomIcon";

const FormattedLabel = ({ label }) => {
  const [title, desc] = label.split(/\r?\n/);

  return <>
    <Typography variant="subtitle1">{title}</Typography>
    {desc && <Typography variant="body3">{desc}</Typography>}
  </>;
};

const SelectableBox = styled(Box)(
  sx({
    boxShadow: 30,
    flex: "1 0 15rem",
    maxWidth: "50%",
    py: [3, 6],
    display: "flex",
    flexFlow: "column",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    gap: 2,
    mb: 2,
    backgroundColor: "primary.grey2",
    borderRadius: 7,
    border: "3px solid",
    borderColor: "primary.grey2",
    transition: "all 0.16s ease-in-out",
    "&:hover": {
      borderColor: "secondary.main",
      color: "primary.dark",
      cursor: "pointer",
      boxShadow: 6,
    },
    "&.is-active": {
      borderColor: "primary.main",
      cursor: "default",
      backgroundColor: "primary.main",
      color: "secondary.main"
    },
  })
);

export default function FinderCard({ label, value, icon, updateData, active }) {
  return (
    <SelectableBox
      onClick={updateData}
      className={active ? "is-active" : ""}>
      <CustomIcon name={icon} width="64" height="64" />
      <FormattedLabel label={ label } />
    </SelectableBox>
  );
}
