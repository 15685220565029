import {
  useTheme,
  Container,
  Typography
} from "@mui/material";

export default function Heading({ title, subtitle, darkMode = true }) {
  const theme = useTheme();
  const titleColor = darkMode ? theme.palette.text.white : theme.palette.primary.dark;
  const subtitleColor = darkMode ? theme.palette.text.white : theme.palette.primary.light;

  return (
    <Container align="center" maxWidth={"md"}>
      <Typography variant="h2" component="h2" mb={1} color={titleColor}>{title}</Typography>
      <Typography paragraph variant="subtitle1" mb={4} color={subtitleColor}>{subtitle}</Typography>
    </Container>
  );
}
