import { useSetRecoilState } from "recoil";
import Cookies from "js-cookie";
import jwt_decode from "jwt-decode";
import {
  useGoogleReCaptcha
} from "react-google-recaptcha-v3";

import { useFetchWrapper } from "_helpers";
import serializeProspect from "_serializers/prospect";
import { authAtom } from "_state";

function useUserActions() {
  const muleServiceUrl = `${process.env.REACT_APP_API_URL}`;
  const authUrl = `${process.env.REACT_APP_AUTH_URL}`;
  const authVersion = `${process.env.REACT_APP_AUTH_VERSION}`;
  const fetchWrapper = useFetchWrapper();
  const setAuth = useSetRecoilState(authAtom);
  const { executeRecaptcha } = useGoogleReCaptcha();

  return {
    login,
    logout,
    signup,
    getDict,
    getUserByUID,
    createProspect,
    verifyUser,
    getCurrentUser,
    postMuleStatus,
    resendConfirmation
  };

  async function getCurrentUser() {
    const jwt = Cookies.get("my_portal_token");

    try {
      const decoded = jwt_decode(jwt);

      return fetchWrapper
        .get(`${muleServiceUrl}/user/me`)
        .then(user => {
          localStorage.setItem("user", JSON.stringify(user));
          setAuth(user);
        });
    } catch (error) {
      setAuth(null);
    }
  }

  function login(email) {
    window.location.href = `${authUrl}/login?email=${email}`;
  }

  function logout() {
    window.location.href = `${authUrl}/logout`;
  }

  async function signup(data) {
    const token = await _getCaptchaToken("signup");

    return fetchWrapper
      .post(`${authUrl}/${authVersion}/users`, { user: serializeProspect(data) }, { "x-recaptcha-token": token });
  }

  async function verifyUser(email) {
    const token = await _getCaptchaToken("verify_user");

    return fetchWrapper
      .get(`${muleServiceUrl}/users/verify?email=${email}`, null, { "x-recaptcha-token": token });
  }

  async function getUserByUID(uuid) {
    const token = await _getCaptchaToken("get_user");

    return fetchWrapper
      .get(`${authUrl}/${authVersion}/users?id=${uuid}`, null, { "x-recaptcha-token": token });
  }

  async function resendConfirmation(uuid) {
    const token = await _getCaptchaToken("get_confirmation");

    return fetchWrapper
      .get(`${authUrl}/${authVersion}/confirmation?id=${uuid}`, null, { "x-recaptcha-token": token });
  }

  async function postMuleStatus(uuid, mule_status) {
    const token = await _getCaptchaToken("post_mule_status");

    return fetchWrapper
      .put(`${authUrl}/${authVersion}/users`, { id: uuid, mule_status }, { "x-recaptcha-token": token });
  }

  async function createProspect(data) {
    const token = await _getCaptchaToken("create_prospect");

    return fetchWrapper
      .post(`${muleServiceUrl}/prospect`, data, { "x-recaptcha-token": token });
  }

  function getDict() {
    return fetchWrapper
      .get(`${muleServiceUrl}/dict`);
  }

  async function _getCaptchaToken(action) {
    if (!executeRecaptcha) {
      console.log("Execute recaptcha not yet available");

      return;
    }

    const token = await executeRecaptcha(action);

    return token;
  }
}

export default useUserActions;
