import useSWR from "swr";
import qs from "qs";

import { useFetchWrapper, laggy } from "_helpers";

function serializeFilter(filters) {
  return {
    ...filters
  };
}

function useProductActions() {
  const muleServiceUrl = `${process.env.REACT_APP_API_URL}`;
  const fetchWrapper = useFetchWrapper();

  return {
    useService,
    useProducts,
    useEquipment
  };

  function useService(product_code) {
    const { data, error } = useSWR(`${muleServiceUrl}/service/${product_code}`);

    return {
      product: data,
      isLoading: !error && !data,
      isError: error
    };
  }

  function useEquipment(product_code) {
    const { data, error } = useSWR(`${muleServiceUrl}/equipment/${product_code}`);

    return {
      product: data,
      isLoading: !error && !data,
      isError: error
    };
  }

  function useProducts(filters) {
    const { data, error, isLagging } = useSWR(`${muleServiceUrl}/faceted_search/product2?` + qs.stringify(
      serializeFilter(filters)
    ), { use: [laggy] });

    return {
      data,
      isLoading: (!error && !data) || isLagging,
      isLagging: isLagging,
      isError: error
    };
  }
}

export default useProductActions;
