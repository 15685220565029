import {
  Typography,
  Button
} from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import Lottie from "react-lottie-player/dist/LottiePlayerLight";

import animation1 from "_assets/animations/animation1";
import { RedirectLayout } from "_layouts";

export default function NotFoundPage() {
  return (
    <RedirectLayout>
      <Lottie
        loop
        animationData={animation1}
        play
        style={{ width: 150, height: 150 }}
      />
      <Typography variant="h3" sx={{ mb: 2 }}>404</Typography>
      <Typography variant="body" sx={{ mb: 6, display: "block" }}>Page not found.</Typography>
      <Button component={RouterLink} to="/" variant="contained" color="secondary">Back to Home</Button>
    </RedirectLayout>
  );
}
