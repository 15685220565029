import { useState } from "react";
import {
  useTheme,
  Container,
  TextField,
  MenuItem,
  Grid,
  Box,
  Typography,
  Button,
  ToggleButton,
  ToggleButtonGroup,
  FormGroup,
  FormControlLabel,
  Checkbox,
  FormLabel,
  FormControl,
  InputAdornment,
} from "@mui/material";
// eslint-disable-next-line
import Map, {Source, Layer} from "react-map-gl";// eslint-disable-line import/no-webpack-loader-syntax
import { styled, experimental_sx as sx } from "@mui/material/styles";
import { useForm, Controller } from "react-hook-form";
import { Link as RouterLink } from "react-router-dom";

import SearchIcon from "@mui/icons-material/Search";
import TerminalSlider from "./_TerminalSlider";
import LinkSwitch from "./_LinkSwitch";
import { PageHero, Carousel } from "_components";
import CatalogueEntry from "../catalogue/_CatalogueEntry";
import { DefaultLayout } from "_layouts";

import geojson from "_assets/maps/geojson/E65WA_Down_Brazil";

const layerStyle = {
  id: "landuse_park",
  type: "fill",
  paint: {
    "fill-color": "#098098",
    "fill-outline-color": "#FF0000",
    "fill-opacity": 0.2,
  }
};

console.log(geojson);

export default function Coverage() {
  const { control, handleSubmit, reset } = useForm();
  const theme = useTheme();
  const [lng] = useState(-5.9);
  const [lat] = useState(48.35);
  const [zoom] = useState(2);
  const [type, setType] = useState("raw");


  const submit = data => {
    console.log(data);
  };

  const handleChange = (event, newAlignment) => {
    setType(newAlignment);
    reset();
  };

  const CoverageForm = styled("form")(
    sx({
      p: 2,
      width: "350px",
      color: "text.dark",
      background: "white",
      borderRadius: 2,
      boxShadow: theme.shadows[6],
      textAlign: "center"
    })
  );

  const BandCheckboxes = () => <FormControl sx={{
    alignItems: "flex-start",
    width: "100%",
    mb: 2
  }}>
    <FormLabel sx={{
      fontWeight: "600"
    }}>
          Band
    </FormLabel>
    <FormGroup row>
      <FormControlLabel
        control={
          <Controller
            control={control}
            name="c"
            defaultValue={true}
            render={({ field: { onChange, value } }) => <Checkbox checked={value} onChange={onChange}/>
            }
          />
        }
        label="C"
      />
      <FormControlLabel
        control={
          <Controller
            control={control}
            name="ka"
            defaultValue={false}
            render={({ field: { onChange, value } }) => <Checkbox checked={value} onChange={onChange}/>
            }
          />
        }
        label="Ka"
      />
      <FormControlLabel
        control={
          <Controller
            control={control}
            name="ku"
            defaultValue={false}
            render={({ field: { onChange, value } }) => <Checkbox checked={value} onChange={onChange}/>
            }
          />
        }
        label="Ku"
      />
    </FormGroup>
  </FormControl>;

  const RawCapacityInputs = () => <>
    <Controller
      render={
        ({ field }) => <TextField
          sx={{
            mb: 2,
            textAlign: "start"
          }}
          label="Region"
          size="small"
          select
          fullWidth
          {...field}>
          {["Europe", "Africa", "Middle East"].map((c, index) => <MenuItem key={index} value={c}>{c}</MenuItem>)}

        </TextField>
      }
      control={control}
      name="region"
      defaultValue={""}
    />
    <FormControl sx={{
      alignItems: "flex-start",
      width: "100%"
    }}>
      <FormLabel sx={{
        fontWeight: "600"
      }}>
            Business
      </FormLabel>
      <FormGroup row>
        <FormControlLabel
          control={
            <Controller
              control={control}
              name="connectivity"
              defaultValue={true}
              render={({ field: { onChange, value } }) => <Checkbox checked={value} onChange={onChange}/>
              }
            />
          }
          label="connectivity"
        />
        <FormControlLabel
          control={
            <Controller
              control={control}
              name="video"
              defaultValue={false}
              render={({ field: { onChange, value } }) => <Checkbox checked={value} onChange={onChange}/>
              }
            />
          }
          label="Video"
        />
      </FormGroup>
    </FormControl>
    <BandCheckboxes />
    <Controller
      render={
        ({ field }) => <TextField
          sx={{ mb: 2 }}
          label="Location"
          placeholder="Fill your exact location"
          {...field}
          InputProps={{
            endAdornment:
                  <InputAdornment position="end">
                    <SearchIcon color="primary" />
                  </InputAdornment>
            ,
          }}
        />
      }
      control={control}
      name="location"
      defaultValue={""}
    />
  </>;

  const ManagedServicesInputs = () => <>
    <Controller
      render={
        ({ field }) => <TextField
          sx={{
            mb: 2,
            textAlign: "start"
          }}
          label="Region"
          size="small"
          select
          fullWidth
          {...field}>
          {["Europe", "Africa", "Middle East"].map((c, index) => <MenuItem key={index} value={c}>{c}</MenuItem>)}

        </TextField>
      }
      control={control}
      name="region"
      defaultValue={""}
    />
    <Controller
      render={
        ({ field }) => <TextField
          sx={{
            mb: 2,
            textAlign: "start"
          }}
          label="Application"
          select
          fullWidth
          {...field}>
          {["Maritime", "Terrestre", "Autre"].map((c, index) => <MenuItem key={index} value={c}>{c}</MenuItem>)}

        </TextField>
      }
      control={control}
      name="application"
      defaultValue={""}
    />
    <BandCheckboxes />
    <TerminalSlider control={control} />
    <Controller
      render={
        ({ field }) => <TextField
          sx={{ mb: 2 }}
          label="Location"
          placeholder="Fill your exact location"
          {...field}
          InputProps={{
            endAdornment:
                  <InputAdornment position="end">
                    <SearchIcon color="primary" />
                  </InputAdornment>
            ,
          }}
        />
      }
      control={control}
      name="location"
      defaultValue={""}
    />
  </>;

  return (
    <DefaultLayout>
      <PageHero mb={4} />
      <Container maxWidth="xl">
        <Typography
          align="center"
          variant="h1"
          component="h1"
          fontSize={"2.625rem"}
          color="text.dark"
          mb={1}>
          Coverage map
        </Typography>
        <Typography
          align="center"
          variant="subtitle1"
          color="text.secondary"
          mb={6}>
          Discover how Eutelsat can help you, whatever you are in the world
        </Typography>
      </Container>
      <Container maxWidth="xl" sx={{ mb: 8, position: "relative" }}>
        <Box sx={{
          position: "absolute",
          display: "flex",
          justifyContent: "space-between",
          p: "0px 48px 0px 0px",
          zIndex: 2,
        }}>
          <CoverageForm
            onSubmit={handleSubmit(submit)}
          >
            <ToggleButtonGroup
              color="primary"
              value={type}
              size="small"
              exclusive
              fullWidth
              sx={{ mb: 2 }}
              onChange={handleChange}
            >
              <ToggleButton value="raw">Raw capacity (Mhz)</ToggleButton>
              <ToggleButton value="managed">Managed services (Mbit)</ToggleButton>
            </ToggleButtonGroup>
            {type === "raw"
              ? <RawCapacityInputs />
              : <ManagedServicesInputs />
            }
            <Button type="submit" variant="contained" color="secondary">Check coverage</Button>
          </CoverageForm>
          {type === "raw"
            ?? <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-end",
                width: "300px",
                mr: 2
              }}
            >
              <LinkSwitch />
            </Box>
          }
        </Box>
        <Map
          initialViewState={{
            longitude: lng,
            latitude: lat,
            zoom: zoom
          }}
          mapboxAccessToken="pk.eyJ1IjoiamdhdXRpZXItZXV0ZWxzYXQiLCJhIjoiY2xiNWJxbjE5MDQyeDNycXRzZzJmeGFpeiJ9.6as0RjTpM8mfvL_6hc1qsg"
          style={{ height: "90vh", borderRadius: "20px" }}
          mapStyle="mapbox://styles/jgautier-eutelsat/cl8vpa1b3000b15quueyy1vyl"
        >
          <Source id="my-data" type="geojson" data={geojson}>
            <Layer {...layerStyle} />
          </Source>
        </Map>
        <Grid sx={{
          alignItems: "center",
          position: "relative",
          zIndex: 10,
          mb: 4,
          px: 4,
          mt: "-20vh",
          background: "linear-gradient(to bottom, transparent, #fff 50%)",
        }} container>
          <Grid item xs={12} lg={3}>
            <Typography variant="h3" component="h2" mb={2}>
                Related Services
            </Typography>
            <Button
              variant="outlined"
              size="sm"
              to={"/catalogue"}
              component={RouterLink}>
                GO TO THE PRODUCT CATALOGUE
            </Button>
          </Grid>
          <Grid item xs={12} lg={9}>
            <Carousel rows={1}>
              {[1,2,3,4].map(plan => <CatalogueEntry
                sx={{ fontSize: "0.875rem", backgroundColor: "primary.grey2", height: "100%", boxShadow: "none", borderRadius: 3 }}
                active={1}
                display={"card"}
                product={plan}
                key={plan.id}
              />)}
            </Carousel>
          </Grid>
        </Grid>
      </Container>
    </DefaultLayout>
  );
}
