import {
  useTheme,
  Container,
  Box,
  Stack,
  Typography,
  Button
} from "@mui/material";
import { useRecoilValue } from "recoil";
import { Link as RouterLink } from "react-router-dom";
import { styled, experimental_sx as sx } from "@mui/material/styles";

import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import CustomIcon from "_components/CustomIcon";
import { authAtom } from "_state";
import HomeVideoUrl from "_assets/home_video.mp4";
import HomeVideoUrlWebm from "_assets/home_video.webm";

export default function HeroUnit() {
  const theme = useTheme();
  const auth = useRecoilValue(authAtom);

  const videoStyle = {
    width: "100%",
    height: "600px",
    position: "absolute",
    zIndex: -1,
    objectFit: "cover",
    objectPosition: "center"
  };

  const ServiceBox = styled(RouterLink)(
    sx({
      flex: "1 0 33%",
      display: "flex",
      flexFlow: "column",
      justifyContent: "center",
      px: [4, 6],
      border: 1,
      borderColor: "#ffffff33",
      color: "#fff",
      textDecoration: "none",
      transition: "all 0.2s ease-in-out",
      "& + &": {
        marginTop: "-1px"
      },
      "& .desc": {
        height: 0,
        opacity: 0,
        transition: "all 0.2s ease-in-out"
      },
      "& .title, & .arrow": {
        transition: "all 0.15s ease-in-out"
      },
      "&:not(:hover) .title": {
        transition: "all 0.25s ease-in-out"
      },
      "&:hover": {
        zIndex: 1,
        backgroundColor: "#003B60BB",
        "& .desc": {
          translate: "0 1em",
          height: "5em",
          opacity: 1
        },
        "& .arrow": {
          translate: "0 100%"
        }
      }
    })
  );

  return <Box>
    <video
      autoPlay={true}
      disablePictureInPicture={true}
      loop={true}
      muted={true}
      style={videoStyle}
    >
      <source src={HomeVideoUrlWebm} type="video/webm"></source>
      <source src={HomeVideoUrl} type="video/mp4"></source>
    </video>

    <Box sx={{ bgcolor: "#011726CC", color: "primary.contrastText", mb: 5 }}>
      <Container maxWidth="xl">
        <Stack direction={["column", "row"]} alignItems="stretch" py={[2, 0]} sx={{ minHeight: "600px" }}>
          <Container maxWidth="xl">
            <Stack alignItems="flex-start" justifyContent="center" sx={{ height: "100%" }}>
              <Typography variant="h1" component="h1" sx={{ fontWeight: "100" }} mt={2} mb={1}>Customer Portal</Typography>
              <Typography variant="body1" sx={{ fontWeight: 500 }} mb={8}>Welcome a board&nbsp;: <strong>{auth.name} {auth.surname}</strong></Typography>

              {/* <Typography variant="body3" mb={2}>
                Your Area manager is<br />{"Luke Skywalker"}<br />
                <Link variant="body4" sx={{ color: theme.palette.secondary.main, fontWeight: 500 }}>{"lSkywalker@rebels.com"}</Link>
              </Typography> */}
              <Button variant="contained" color="secondary" sx={{ marginBottom: 2 }}>Contact Eutelsat</Button>
            </Stack>
          </Container>

          <Stack alignItems="stretch" sx={{ flex: ["1 0 auto", "1 0 400px"] }}>
            <ServiceBox to="/catalogue-index">
              <Stack direction="row" spacing={1} alignItems="center">
                <Box>
                  <Stack className="title" direction="row" spacing={1} alignItems="center">
                    <CustomIcon name="icon_productcatalogue" width="34" stroke={theme.palette.secondary.main}/>
                    <Typography fontSize={16} fontWeight={500}>Product Catalogue</Typography>
                  </Stack>
                  <Typography m={0} className="desc" paragraph fontSize={12} lineHeight={1.4} color={theme.palette.secondary.main}>A comprehensive overview of the Eutelsat product suite, from capacity offers, infrastructure solutions, to managed services.</Typography>
                </Box>
                <ArrowForwardIosIcon className="arrow" sx={{ color: theme.palette.secondary.main, fontSize: 14 }} />
              </Stack>
            </ServiceBox>
            <ServiceBox to="/coverage">
              <Stack direction="row" spacing={1} alignItems="center">
                <Box>
                  <Stack className="title" direction="row" spacing={1} alignItems="center">
                    <CustomIcon name="icon_coverage" width="34" stroke={theme.palette.secondary.main}/>
                    <Typography fontSize={16} fontWeight={500}>Coverages</Typography>
                  </Stack>
                  <Typography m={0} className="desc" paragraph fontSize={12} lineHeight={1.4} color={theme.palette.secondary.main}>Choose your target regions and the services you want to deliver and find out how we can support you.</Typography>
                </Box>
                <ArrowForwardIosIcon className="arrow" sx={{ color: theme.palette.secondary.main, fontSize: 14 }} />
              </Stack>
            </ServiceBox>
            <ServiceBox to="/contact">
              <Stack direction="row" spacing={1} alignItems="center">
                <Box>
                  <Stack className="title" direction="row" spacing={1} alignItems="center">
                    <CustomIcon name="icon_contact" width="34" stroke={theme.palette.secondary.main}/>
                    <Typography fontSize={16} fontWeight={500}>Contact Eutelsat</Typography>
                  </Stack>
                  <Typography m={0} className="desc" paragraph fontSize={12} lineHeight={1.4} color={theme.palette.secondary.main}>Contact a sales representative for a complete proposal based on your requirements.</Typography>
                </Box>
                <ArrowForwardIosIcon className="arrow" sx={{ color: theme.palette.secondary.main, fontSize: 14 }} />
              </Stack>
            </ServiceBox>
          </Stack>

        </Stack>
      </Container>
    </Box>
  </Box>;
}
