import {
  Stepper,
  Step,
  StepLabel
}
  from "@mui/material";

import { styled, experimental_sx as sx } from "@mui/material/styles";

import StepConnector, { stepConnectorClasses } from "@mui/material/StepConnector";

const ColorlibConnector = styled(StepConnector)(sx({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 25
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      height: 0,
      borderTop: "4px dotted",
      borderTopColor: "secondary.main"
    }
  },

  [`& .${stepConnectorClasses.line}`]: {
    height: 0,
    borderTop: "4px dotted",
    borderTopColor: "secondary.main"
  }
}));

const ColorlibStepIconRoot = styled("div")(({ ownerState }) => sx({
  backgroundColor: "primary.smoke",
  zIndex: 1,
  color: "primary.main",
  width: 50,
  height: 50,
  display: "flex",
  borderRadius: "50%",
  justifyContent: "center",
  alignItems: "center",
  ...ownerState.active && {
    backgroundColor: "secondary.main"
  },
  ...ownerState.completed && {
    backgroundColor: "primary.main",
    color: "primary.smoke"
  }
}));

function ColorlibStepIcon(props) {
  const { active, completed, className } = props;

  return (
    <ColorlibStepIconRoot ownerState={{ completed, active }} className={className}>
      {props.icon}
    </ColorlibStepIconRoot>
  );
}

export default function CustomStepper({ steps, activeStep, sx }) {
  return (
    <Stepper sx={sx} alternativeLabel activeStep={activeStep} connector={<ColorlibConnector />}>
      {steps.map(step => <Step key={step.label}>
        <StepLabel icon={step.icon} StepIconComponent={ColorlibStepIcon}>{step.label}</StepLabel>
      </Step>
      )}
    </Stepper>
  );
}
