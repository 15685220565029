import {
  useTheme,
  Typography,
  Paper,
  Container,
  Grid,
  Stack,
  Box,
  Button
} from "@mui/material";
import { styled, experimental_sx as sx } from "@mui/material/styles";
import { Link as RouterLink } from "react-router-dom";
import CustomIcon from "_components/CustomIcon";

export default function FinderPaper() {
  const theme = useTheme();

  const SmokePaper = styled(Paper)(
    sx({
      px: [2, 4, 6],
      py: [2, 3],
      backgroundColor: "primary.smoke",
      borderColor: "primary.smoke"
    })
  );

  const IconBox = styled(Box)(
    sx({
      p: 1,
      borderRadius: 2,
      border: 1,
      borderColor: "secondary.main",
      backgroundColor: "primary.main"
    })
  );

  return (
    <Container maxWidth="lg">
      <SmokePaper>
        <Grid container spacing={4} alignItems="center">
          <Grid item md={5} xs={12}>
            <Stack direction="row" spacing={4} alignItems="center">
              <IconBox>
                <CustomIcon name="icon_finder" width="75" stroke={theme.palette.secondary.main} />
              </IconBox>
              <Typography variant="h2">Solution Finder</Typography>
            </Stack>
          </Grid>
          <Grid item md={4} xs={12}>
            <Typography variant="subtitle1" color={theme.palette.primary.light}>Start solving your business challenges with our easy to use Solution Finder tool.</Typography>
          </Grid>
          <Grid item md={3} xs={12} textAlign="right">
            <Button variant="contained" color="secondary" size="sm" to="/solution-finder" component={RouterLink}>Let's go</Button>
          </Grid>
        </Grid>
      </SmokePaper>
    </Container>
  );
}
