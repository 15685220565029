import { useState } from "react";
import {
  useTheme,
  Button,
  Container,
  Grid,
  Stack,
  Typography,
  Pagination,
  Box,
  Chip
} from "@mui/material";
import { experimental_sx as sx } from "@mui/material/styles";
import Lottie from "react-lottie-player/dist/LottiePlayerLight";
import CloseIcon from "@mui/icons-material/Close";

import SearchEngine from "./_SearchEngine";
import FinderPaper from "./_FinderPaper";
import CatalogueEntry from "./_CatalogueEntry";
import useQueryAsState from "_helpers/use-querystate";
import { DefaultLayout } from "_layouts";
import { PageHero } from "_components";
import { useProductActions } from "_actions";
import animation1 from "_assets/animations/animation1";
import noResultImg from "_assets/images/no-result.svg";
import CustomIcon from "_components/CustomIcon";

const displayButtonStyle = isActive => sx({
  minWidth: 0,
  p: "0.25rem",
  backgroundColor: isActive ? "primary.main" : "primary.smoke",
  color: isActive ? "secondary.main" : "primary.main",
  pointerEvents: isActive ? "none" : "initial",
  border: 1,
  borderColor: isActive ? "transparent" : "primary.smokeBorder",
});

function NoResult() {
  return <Box sx={{ display: "flex", alignItems: "center", flexDirection: "column", mt: 3 }}>
    <img src={noResultImg} alt="" />
    <Typography mt={3} variant="h4">No result found</Typography>
    <Typography mb={3} variant="body2" align="center">We didn’t find what you looked for.<br/>Try again with all searching tools</Typography>
  </Box>;
}

export default function Catalogue() {
  const theme = useTheme();
  const [queryParams, setQueryParams] = useQueryAsState({});
  const page_size = 8;
  const productActions = useProductActions();
  const [displayMode, setDisplayMode] = useState("list");

  const { data, isLoading, isError, isLagging } = productActions.useProducts({
    ...queryParams,
    page_size
  });

  const { page_results, total_products_count, picklist_fields } = data || {};
  const pageCount = total_products_count ? Math.ceil(total_products_count / page_size) : 0;

  // Get only query params associated to facet filter
  const filters = Object.keys(queryParams)
    .filter(param => picklist_fields && picklist_fields.find(field => field.technical_field === param))
    .reduce((acc, current) => {
      acc[current] = queryParams[current];

      return acc;
    }, {});

  function addFilter(k, v) {
    setQueryParams({
      ...queryParams,
      [k]: v,
      index: 0
    });
  }

  function removeFilter(k) {
    const f = { ...queryParams, index: 0 };

    delete f[k];
    setQueryParams(f);
  }

  function resetQuery() {
    removeFilter("query");
  }

  return (
    <DefaultLayout>
      <PageHero />
      {isError
        ? <div>Error</div>

        : <Container sx={{ minHeight: "60vh", position: "relative" }} maxWidth="xl">
          <Grid container spacing={4}>
            <Grid item sm={12} md={4} style={{ opacity: isLagging ? 0.7 : 1 }}>
              <SearchEngine
                facets={picklist_fields}
                filters={filters}
                addFilter={addFilter}
                removeFilter={removeFilter}
                initialQuery={queryParams.query}
              />
            </Grid>

            <Grid item sm={12} md={8} my={4}>
              <Typography
                align="center"
                variant="h1"
                component="h1"
                fontSize={"2.625rem"}
                color={theme.palette.text.dark}
                mb={1}>
              Discover our catalogue
              </Typography>
              <Typography
                align="center"
                variant="subtitle1"
                color={theme.palette.text.secondary}
                mb={4}>
              Discover our Managed Services for Internet Connectivity
              </Typography>

              <FinderPaper />
              <Stack direction="row" alignItems="center" py={1} gap={1} mb={4} sx={{ borderBottom: 1, borderBottomColor: "primary.smokeBorder" }}>
                <Typography variant="h5" mr="auto">{ total_products_count } result(s)</Typography>
                { queryParams.query && <Box sx={{ display: "flex", alignItems: "center" }} mr={8}>
                  <Typography mr={2} variant="body2" >In the Full text search :</Typography>
                  <Chip
                    onDelete={resetQuery}
                    size="small"
                    label={queryParams.query}
                    color="info"
                    deleteIcon={<CloseIcon />}
                  />
                </Box>
                }
                <Button
                  onClick={() => setDisplayMode("list")}
                  sx={displayButtonStyle(displayMode === "list")}
                >
                  <CustomIcon name="icon_displaylist" width="24"/>
                </Button>
                <Button
                  onClick={() => setDisplayMode("card")}
                  sx={displayButtonStyle(displayMode === "card")}
                >
                  <CustomIcon name="icon_displaycard" width="24"/>
                </Button>
              </Stack>
              { isLoading
                ? <Box sx={{ display: "flex", justifyContent: "center" }}>
                  <Lottie
                    loop
                    animationData={animation1}
                    play
                    style={{ width: 150, height: 150 }}
                  />
                </Box>
                : <>
                  {total_products_count === 0
                    ? <NoResult/>
                    : <Grid container spacing={displayMode === "card" ? 6 : 0}>
                      {page_results.map(p => <Grid item key={p.ProductCode} xs={12} sm={displayMode === "card" ? [6] : 12}>
                        <CatalogueEntry product={p} display={ displayMode } />
                      </Grid>)}
                    </Grid>
                  }
                </>
              }

              {pageCount > 1
              && <Box mt={2} sx={{ display: "flex", justifyContent: "center" }}>
                <Pagination
                  count={pageCount}
                  onChange={(evt, page) => setQueryParams({ ...queryParams, index: page - 1 })}
                  page={queryParams.index ? parseInt(queryParams.index) + 1 : 1}
                  color="secondary"
                />
              </Box>
              }

            </Grid>
          </Grid>
        </Container>
      }
    </DefaultLayout>
  );
}
