import {
  Box,
  Container,
  Stack,
} from "@mui/material";
import { Link as ScrollLink } from "react-scroll";
import { experimental_sx as sx } from "@mui/material/styles";

const AnchorNavigation = function({ links }) {
  const subNavLinkStyle = sx({
    "a": {
      position: "relative",
      px: 0,
      py: .1,
      color: "text.secondary",
      textTransform: "uppercase",
      textDecoration: "none",
      fontWeight: 500,
      fontSize: ["0.875em", "1em", "1.125rem"],
      whiteSpace: "nowrap",
      cursor: "pointer",
      transition: "all .16s ease",
      "&:after": {
        content: "''",
        position: "absolute",
        bottom: 0,
        left: 0,
        width: "100%",
        height: "3px",
        borderRadius: "3px",
        backgroundColor: "secondary.main",
        transition: "all .16s ease",
      },
      "&:hover:after, &.active:after": {
        backgroundColor: "currentColor"
      },
      "&:hover:after": {
        width: "30%",
      },
      "&:hover": {
        color: "text.primary",
      },
      "&.active": {
        color: "text.dark",
        "&:after": {
          width: "100%",
        }
      }
    }
  });

  return <Box sx={{ zIndex: 1, position: "sticky", top: 52, my: 2, py: 1, background: "#fffe", overflowX: "auto" }}>
    <Container maxWidth="lg" sx={subNavLinkStyle}>
      <Stack direction="row" gap={[1, 2, 3, 8]}>
        {links.map(link => <ScrollLink activeClass="active" smooth spy to={link.anchor} key={link.anchor} offset={-80} delay={100}>
          {link.label}
        </ScrollLink>)}
      </Stack>
    </Container>
  </Box>;
};

export default AnchorNavigation;
