import {
  Box,
  Container,
  Stack,
  Typography,
  Grid,
  Link,
  Button,
  Breadcrumbs
} from "@mui/material";
import { Link as RouterLink, useParams } from "react-router-dom";
import Lottie from "react-lottie-player/dist/LottiePlayerLight";

import CatalogueEntry from "./_CatalogueEntry";
import EquipmentCard from "./_EquipmentCard";
import DocumentCard from "./_DocumentCard";
import SectionTitle from "./_SectionTitle";
import BenefitsList from "./_BenefitsList";
import AnchorNavigation from "./_AnchorNavigation";
import { DefaultLayout } from "_layouts";
import { PageHero, Carousel } from "_components";
import animation1 from "_assets/animations/animation1";
import CustomIcon from "_components/CustomIcon";
import { useProductActions } from "_actions";
import mapImg from "_assets/images/carte.svg";

export default function Service() {
  const { product_code } = useParams();
  const productActions = useProductActions();
  const { product } = productActions.useService(product_code);

  const links = product
    ? [
      ...product.MKT_KeyBenefits__c.length > 0 ? [{ label: "Key Benefits", anchor: "keyBenefit" }] : [],
      ...product.MKT_Features__c.length > 0 ? [{ label: "Features", anchor: "features" }] : [],
      ...product.Plans && product.Plans.concat(product.Options).length > 0 ? [{ label: "Plans & Options", anchor: "plans" }] : [],
      ...product.MKT_Equipement__c.length > 0 ? [{ label: "Equipment", anchor: "equipment" }] : [],
      { label: "Coverage", anchor: "coverage" },
      ...product.MKT_Documents__c.length > 0 ? [{ label: "Documentation", anchor: "doc" }] : []
    ]
    : [];

  return (
    <DefaultLayout>
      <PageHero mb={4} />

      <Container maxWidth="xl">
        <Typography
          align="center"
          variant="h1"
          component="h1"
          fontSize={"2.625rem"}
          color="text.dark"
          mb={1}>
          Discover our catalogue
        </Typography>
        <Typography
          align="center"
          variant="subtitle1"
          color="text.secondary"
          mb={6}>
          Discover our Managed Services for Internet Connectivity
        </Typography>
      </Container>
      {!product
        ? <Box sx={{ display: "flex", justifyContent: "center", pb: 10 }}>
          <Lottie
            loop
            animationData={animation1}
            play
            style={{ width: 150, height: 150 }}
          />
        </Box>
        : <>
          <Container maxWidth="lg">
            <Breadcrumbs aria-label="breadcrumb" separator="›" mb={3}>
              <Link underline="none" component={RouterLink} to="/catalogue">
                <Typography variant="body2" sx={{ color: "text.secondary" }}>Products Catalogue</Typography>
              </Link>
              <Typography variant="body2" sx={{ color: "text.secondary" }}>{product?.Name}</Typography>
            </Breadcrumbs>
            <Stack gap={4} direction="row" alignItems="flex-start" width="100%">
              <Box sx={{
                flex: "0 0 200px",
                width: 200,
                height: 200,
                backgroundColor: "primary.main",
                color: "secondary.main",
                borderRadius: 2,
                justifyContent: "center",
                display: "flex",
                alignItems: "center"
              }}>
                {product.MKT_Icon__c
                  ? <img src={"data:image/svg+xml;base64," + product.MKT_Icon__c} alt="icon" width="70%"/>
                  : <CustomIcon name="icon_eutelsat" width="70%"/>}
              </Box>
              <Stack width="100%">
                <Stack gap={2} direction="row">
                  <Box textTransform="uppercase" mb={1} flexBasis="100%">
                    <Typography variant="h2" color="text.primary" mb={0}>{product?.Name}</Typography>
                    <Typography variant="subtitle1" color="text.secondary" fontWeight={600}>{product.Family}</Typography>
                  </Box>
                  <Stack gap={2} direction="row" ml="auto" alignItems="flex-start">
                    <Box sx={{ px: 2, py: 1, fontSize: "0.875rem", fontWeight: 600, color: "secondary.main", backgroundColor: "primary.smoke", borderRadius: 99 }}>{product.MKT_Category__c}</Box>
                  </Stack>
                </Stack>
                <Typography paragraph fontSize="1.125rem" lineHeight="1.4">
                  {product.Description}
                </Typography>
              </Stack>
            </Stack>
          </Container>

          { links.length > 1 && <AnchorNavigation links={links}/> }
          <Container maxWidth="lg">
            { product.MKT_KeyBenefits__c.length > 0
            && <Box id="keyBenefit" py={4}>
              <SectionTitle>Key benefits</SectionTitle>
              <BenefitsList benefits={product.MKT_KeyBenefits__c} />
            </Box>
            }
            { product.MKT_Features__c.length > 0
            && <Box id="features" py={4}>
              <SectionTitle>Features</SectionTitle>
              <Container maxWidth="lg">
                {product.MKT_Features__c.map(feat => <Box sx={{ mb: 2, pl: 5 }} key={ feat.Name }>
                  <Box sx={{ display: "flex", alignContent: "space-between" }}>
                    <Typography sx={{ width: "200px" }}><b>{feat.Name}:</b></Typography>
                    <Typography> {feat.Description}</Typography>
                  </Box>
                </Box>
                )}
              </Container>
            </Box>
            }
            { product.Plans
            && <Box id="plans" py={4}>
              <SectionTitle>Plans & options</SectionTitle>
              <Grid container>
                <Grid item xs={12} lg={9}>
                  <Carousel>
                    {product.Plans.concat(product.Options).map(plan => <CatalogueEntry
                      sx={{ fontSize: "0.875rem", backgroundColor: "primary.grey2", height: "100%", boxShadow: "none", borderRadius: 3 }}
                      active={1}
                      display={"card"}
                      product={plan}
                      key={plan.id}
                    />)}
                  </Carousel>
                </Grid>
                <Grid item xs={12} lg={3}>
                  <Stack alignItems="center" justifyContent="center" gap={2} height="100%">
                    <CustomIcon name="icon_productcatalogue" width="80" />
                    <Button
                      variant="contained"
                      color="secondary"
                      to="/catalogue"
                      component={RouterLink}>
                  Back to catalogue
                    </Button>
                  </Stack>
                </Grid>
              </Grid>
            </Box>
            }
            { product.MKT_Equipement__c.length > 0
            && <Box id="equipment" py={4}>
              <SectionTitle>Equipment</SectionTitle>
              <Grid container>
                <Grid item lg={9}>
                  <Carousel >
                    {product.MKT_Equipement__c.map(equipment => <Box key={equipment.ProductCode} style={{ padding: 40 }}>
                      <EquipmentCard sx={{ fontSize: "0.875rem" }} equipment={equipment}/>
                    </Box>)}
                  </Carousel>
                </Grid>
                <Grid item lg={3}>
                  <Stack alignItems="center" justifyContent="center" gap={2} height="100%">
                    <CustomIcon name="icon_modem" width="80" />
                    <Button
                      variant="contained"
                      color="secondary"
                      to="/catalogue?MKT_Category__c=Equipment"
                      component={RouterLink}>
                  See all equipment
                    </Button>
                  </Stack>
                </Grid>
              </Grid>
            </Box>
            }
            <Box id="coverage" py={4}>
              <SectionTitle>Coverage</SectionTitle>
              <Box mb={8}
                sx={{ position: "relative",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  textAlign: "center",
                  background: `url(${mapImg})`,
                  backgroundPosition: "center",
                  backgroundSize: "contain",
                  backgroundRepeat: "no-repeat",
                  width: "100%",
                  aspectRatio: "2.53"
                }}
              >
                <Button component={RouterLink} sx={{ position: "absolute" }} variant="contained" to="/coverage" color="secondary">Check coverage map</Button>
              </Box>
            </Box>
            { product.MKT_Documents__c.length > 0
            && <Box id="doc" py={4}>
              <SectionTitle>Doc</SectionTitle>
              <Grid container spacing={4}>
                {product.MKT_Documents__c.map(doc => <Grid item xs={12} sm={6} md={6} lg={6} key={doc.Link}>
                  <DocumentCard sx={{ fontSize: "0.875rem", borderRadius: 2 }} display={"card"} doc={doc} />
                </Grid>)}
              </Grid>
            </Box>
            }

          </Container>
        </>}
    </DefaultLayout>
  );
}
