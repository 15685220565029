export default function serializeProspect(prospect) {
  const {
    phonePrefix,
    phone,
    ...user
  } = prospect;

  return {
    ...user,
    phone_number: phonePrefix + phone
  };
}
