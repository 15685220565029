import { useEffect, useState } from "react";
import { useRecoilValue } from "recoil";

import {
  useTheme,
  Grid,
  Container,
  Paper,
  Box,
  Button,
  Typography
} from "@mui/material";
import { styled, experimental_sx as sx } from "@mui/material/styles";

import HeroUnit from "./_HeroUnit";
import Signup from "./_Signup";
import Signin from "./_Signin";
import { DefaultLayout } from "_layouts";
import { history } from "_helpers";
import { authAtom } from "_state";
import { Heading } from "_components";
import CustomIcon from "_components/CustomIcon";

const FeaturePaper = styled(Paper)(
  sx({
    p: 4,
    borderRadius: 2,
    backgroundColor: "primary.smoke",
    height: "100%",
    "&:hover": {
      cursor: "pointer",
      backgroundColor: "#ECF6FA"
    }
  })
);

const ExtranetPaper = styled(Paper)(
  sx({
    borderRadius: 2,
    p: 4,
    background: "none",
    borderColor: "primary.main",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    height: "100%",
    "&:hover": {
      cursor: "pointer",
      backgroundColor: "primary.main"
    }
  })
);

export default function Landing() {
  const theme = useTheme();
  const auth = useRecoilValue(authAtom);
  const [signupOpen, setSignupOpen] = useState(false);
  const [signinOpen, setSigninOpen] = useState(false);

  useEffect(() => {
    // redirect to home if already logged in
    if (auth) {
      history.replace("/home");
    }
  }, [auth]);

  function toogleSign() {
    setSignupOpen(!signupOpen);
    setSigninOpen(signupOpen);
  }

  return (
    <DefaultLayout>
      <HeroUnit />
      <Signup onToggle={toogleSign} onClose={() => setSignupOpen(false)} open={signupOpen}/>
      <Signin onToggle={toogleSign} onClose={() => setSigninOpen(false)} open={signinOpen}/>

      <Container maxWidth={"xl"} sx={{ mb: 8 }}>
        <Grid container spacing={[2, 4, 8]} mb={4} mt={["-24vh"]}>
          <Grid item md={6} sm={12} xs={12}>
            <Paper
              align="center"
              elevation={12}
              sx={{ p: [2, 4, 6], paddingBottom: [4], borderRadius: 2, height: "100%", display: "flex", flexDirection: "column", alignItems: "center" }}
            >
              <CustomIcon name="icon_newuser" width="70" stroke={theme.palette.secondary.main} />
              <Typography variant="h3" component="h2" mt={2} mb={1}>New User</Typography>
              <Typography sx={{ mb: 2 }} paragraph color={theme.palette.primary.light}>
              If you are looking to work with Eutelsat, create an account in just a few clicks. You will also be able to explore our catalogue of products and services.
              </Typography>
              <Button sx={{ marginTop: "auto" }} onClick={() => setSignupOpen(true)} variant="contained" color="secondary" size="sm">Create an account</Button>
            </Paper>
          </Grid>
          <Grid item md={6} sm={12} xs={12}>
            <Paper
              align="center"
              elevation={12}
              sx={{ p: [2, 4, 6], paddingBottom: [4], borderRadius: 2, height: "100%", display: "flex", flexDirection: "column", alignItems: "center", position: "relative" }}
            >
              <CustomIcon name="icon_padlock" width="70" stroke={theme.palette.secondary.main} />
              <Typography variant="h3" component="h2" mt={2} mb={1}>Existing User</Typography>
              <Typography sx={{ mb: 2 }} paragraph color={theme.palette.primary.light}>
              If you already have an account or access to Eutelsat's BtoB tools*please use your usual login details to connect
              </Typography>
              <Button sx={{ marginTop: "auto" }} onClick={() => setSigninOpen(true)} variant="outlined" size="sm">Connect to your account</Button>
              <Typography sx={{
                fontSize: "0.7rem",
                color: "text.secondary",
                fontStyle: "italic",
                position: "absolute",
                bottom: "15px",
                left: "15px"
              }}
              >
                *KONNECT Wholesale Portal, ADVANCE Partner Portal, e-portal
              </Typography>
            </Paper>
          </Grid>
        </Grid>
      </Container>

      <Heading
        title="Key Features of your Portal"
        darkMode={false}
      />

      <Container align="center" maxWidth={"xl"}>
        <Grid container spacing={3} mb={4}>
          <Grid item lg={3} md={6} xs={12}>
            <FeaturePaper onClick={() => setSigninOpen(true)} align="center" elevation={0}>
              <CustomIcon name="icon_finder" width="70" stroke={theme.palette.secondary.main}/>
              <Typography variant="h4" component="h3" mt={2} mb={1}>Solution finder</Typography>
              <Typography paragraph color={theme.palette.primary.light}>
              Start solving your business challenges with our easy to use Solution Finder tool.
              </Typography>
            </FeaturePaper>
          </Grid>
          <Grid item lg={3} md={6} xs={12}>
            <FeaturePaper onClick={() => setSigninOpen(true)} align="center" elevation={0}>
              <CustomIcon name="icon_productcatalogue" width="70" stroke={theme.palette.secondary.main}/>
              <Typography variant="h4" component="h3" mt={2} mb={1}>Product catalogue</Typography>
              <Typography paragraph color={theme.palette.primary.light}>
              Discover the Eutelsat product suite, from capacity offers, infrastructure solutions to managed services.
              </Typography>
            </FeaturePaper>
          </Grid>
          <Grid item lg={3} md={6} xs={12}>
            <FeaturePaper onClick={() => setSigninOpen(true)} align="center" elevation={0}>
              <CustomIcon name="icon_coverage" width="70" stroke={theme.palette.secondary.main}/>
              <Typography variant="h4" component="h3" mt={2} mb={1}>Coverage</Typography>
              <Typography paragraph color={theme.palette.primary.light}>
              Choose your target regions and the services you want to deliver and find out how we can support you.
              </Typography>
            </FeaturePaper>
          </Grid>
          <Grid item lg={3} md={6} xs={12}>
            <FeaturePaper onClick={() => setSigninOpen(true)} align="center" elevation={0}>
              <CustomIcon name="icon_contact" width="70" stroke={theme.palette.secondary.main}/>
              <Typography variant="h4" component="h3" mt={2} mb={1}>Schedule an appointment</Typography>
              <Typography paragraph color={theme.palette.primary.light}>
              Contact a sales representative for a complete proposal based on your requirements.
              </Typography>
            </FeaturePaper>
          </Grid>
        </Grid>

      </Container>

      <Box sx={{ p: 6, background: theme.palette.primary.dark, color: theme.palette.primary.smoke }} align="center">

        <Heading
          title="Other Eutelsat tools"
        />

        <Container maxWidth={"xl"}>
          <Grid container spacing={3} mb={4}>
            <Grid item md={6} xs={12}>
              <ExtranetPaper align="center" elevation={0} variant="outlined">
                <CustomIcon name="icon_siteextranet" width="70" stroke={theme.palette.secondary.main} />
                <Typography color={theme.palette.primary.smoke} variant="h4" component="h3" mt={2} mb={1}>Extranet</Typography>
                <Typography sx={{ px: 6, mb: 4 }} variant="subtitle2" paragraph color={theme.palette.text.light} maxWidth="sm">
                If you already have an Extranet account please use your usual login details to connect.
                </Typography>
                <Button sx={{ marginTop: "auto" }} variant="contained" color="secondary" size="sm">Connect</Button>
              </ExtranetPaper>
            </Grid>
            <Grid item md={6} xs={12}>
              <ExtranetPaper align="center" elevation={0} variant="outlined">
                <CustomIcon name="icon_siteextranet" width="70" stroke={theme.palette.secondary.main} />
                <Typography color={theme.palette.primary.smoke} variant="h4" component="h3" mt={2} mb={1}>Konnect portal</Typography>
                <Typography sx={{ px: 6, mb: 4 }} variant="subtitle2" paragraph color={theme.palette.text.light} maxWidth="sm">
                  If you 're a Konnet retailler please use your usual details login to connect.
                </Typography>
                <Button sx={{ marginTop: "auto" }} variant="contained" color="secondary" size="sm">Connect</Button>
              </ExtranetPaper>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </DefaultLayout>
  );
}
