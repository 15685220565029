import { useEffect, useRef } from "react";
import {
  Box,
  TextField,
  Button,
  Link
}
  from "@mui/material";

import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";

import { CustomDialog } from "_components";
import { useUserActions } from "_actions";

export default function Signin({ onClose, open, onToggle }) {
  const userActions = useUserActions();

  const validationSchema = Yup.object().shape({
    email: Yup.string().required("email is required").email("Email is invalid")
  });

  const formOptions = { resolver: yupResolver(validationSchema) };
  const { register, handleSubmit, formState } = useForm(formOptions);
  const { errors, isSubmitting } = formState;

  async function onSubmit({ email, password = "test" }) {
    return userActions.login(email, password).catch(error => {
      console.log(error);
    });
  }

  const inputRef = useRef(null);

  useEffect(() => {
    const timeout = setTimeout(() => {
      open && inputRef?.current?.focus();
    }, 100);

    return () => {
      clearTimeout(timeout);
    };
  }, [open]);

  return (
    <CustomDialog
      open={open}
      onClose={onClose}
      title="Connect to your account"
    >
      <Box sx={{ mt: 1 }}>

        <form action={process.env.REACT_APP_AUTH_URL} method="GET" onSubmit={handleSubmit(onSubmit)}>
          {errors.apiError && <div>{errors.apiError?.message}</div>}
          <TextField
            fullWidth
            inputRef={inputRef}
            sx={{ marginBottom: 2 }}
            label="Email(login: test@test.com)"
            variant="outlined"
            error={errors.email?.message}
            helperText={errors.email?.message}
            {...register("email")}
          />

          <Link href={
            "https://skylogicb2cpartnerdemo.b2clogin.com/skylogicb2cpartnerdemo.onmicrosoft.com/oauth2/v2.0/authorize?p=B2C_1_reset_password_customer_journey&client_id=34b5b438-d6eb-4d4a-9279-513680fa26fa&nonce=defaultNonce&redirect_uri=" + process.env.REACT_APP_AUTH_URL + "%2Fusers%2Fauth%2Fazure_activedirectory_v2%2Fcallback&scope=openid&response_type=id_token&prompt=login&response_mode=form_post"
          }
          variant="body3">Forgot your password</Link>

          <Box align="center" sx={{ my: 4 }}>
            <Button type="submit" variant="contained" color="secondary" disableElevation disabled={isSubmitting} ml={1} sx={{ minWidth: "10em" }}>
              {isSubmitting ? <span>Loading...</span> : "Validate"}
            </Button>
          </Box>

          <Box
            sx={{ paddingTop: 2, display: "flex", justifyContent: "space-between", alignItems: "center", fontSize: "0.75rem" }}
          >
            {onToggle && <Link onClick={() => onToggle() } variant="body3">Create an account ?</Link>}
            {<Link href="#" variant="body3">Contact us</Link>}

          </Box>
        </form>
      </Box>
    </CustomDialog>
  );
}
