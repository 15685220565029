import {
  useTheme,
  Box,
  Stack,
  Typography,
  Link,
} from "@mui/material";
import { useMemo } from "react";
import { Link as RouterLink } from "react-router-dom";
import { styled, experimental_sx as sx } from "@mui/material/styles";
import CustomIcon from "_components/CustomIcon";

export default function CatalogueEntry(props) {
  const muleServiceUrl = `${process.env.REACT_APP_API_URL}`;
  const { product, display } = props;
  const theme = useTheme();
  const cardDisplay = display === "card";

  const entryStyle = cardDisplay
    ? sx({
      alignItems: "center",
      height: "100%",
      gap: 2,
      p: 2,
      borderRadius: 4,
      boxShadow: theme.shadows[6],
    })
    : sx({
      flexDirection: "row",
      gap: 2,
      py: 2
    });

  const Entry = styled(Stack)(entryStyle);

  const productLink = useMemo(() => {
    switch (product.MKT_Category__c) {
      case "Satellite":
        return `/satellites/${product.ProductCode}`;

      case "Equipment":
        return `/equipments/${product.ProductCode}`;

      default:
        return `/services/${product.ProductCode}`;
    }
  }, [product]);

  const productImg = useMemo(() => {
    if (!product.MKT_Icon__c) {
      if (product.MKT_Category__c === "Equipment") {
        return <img src={muleServiceUrl + "/content/faceted_search/icon_terminal"} alt="icon" width="60" height="60"/>;
      }

      return <img src={muleServiceUrl + "/content/faceted_search/icon_eutelsat"} alt="icon" width="60" height="60"/>;
    }

    if (product.MKT_Category__c === "Equipment") {
      return <img src={muleServiceUrl + "/content/faceted_search/icon_terminal"} alt="icon" width="60" height="60"/>;
    }

    return <img src={muleServiceUrl + "/content/faceted_search/" + product.MKT_Icon__c} alt="icon" width="60" height="60"/>;
  }, [product]);

  return <Entry sx={{ fontSize: "1rem", ...props.sx }}>
    {cardDisplay
    && <Stack gap={2} direction="row" alignItems="center" width="100%" justifyContent="end">
      { product.MKT_Category__c && <Box sx={{ px: 2, py: 1, fontSize: "0.75em", fontWeight: 600, color: "secondary.main", backgroundColor: "#00EBFF10", borderRadius: 99 }}>{product.MKT_Category__c}</Box>}
      { !product.MKT_Category__c && <Box sx={{ px: 2, py: 1, fontSize: "0.75em", fontWeight: 600, color: "secondary.main", backgroundColor: "#00EBFF10", borderRadius: 99 }}>Option</Box>}
    </Stack>
    }
    <Link component={RouterLink}
      to={productLink}
      sx={{
        flex: "0 0 110px",
        width: 110,
        height: 110,
        backgroundColor: "primary.main",
        color: "secondary.main",
        borderRadius: 3,
        justifyContent: "center",
        display: "flex",
        alignItems: "center"
      }}
    >
      {productImg}
    </Link>
    <Stack textAlign={cardDisplay ? "center" : "initial"} width="100%">
      <Stack gap={2} direction="row" justifyContent="space-between" alignItems="flex-start">
        <Box textTransform="uppercase" mb={1} width="100%">
          <Link component={RouterLink} to={productLink} underline="hover" sx={{ color: "text.primary" }} >
            <Typography variant="h3" fontSize="0.9rem" color="text.primary" mb={0}>{ product.Name }</Typography>
          </Link>
          <Typography fontSize="0.7rem" variant="subtitle2" color="text.secondary" fontWeight={600}>{ product.Family }</Typography>
        </Box>
        {!cardDisplay
          && <Stack gap={2} direction="row" ml="auto" alignItems="center">
            { product.MKT_Category__c && <Box sx={{ px: 2, py: 1, fontSize: "0.75em", fontWeight: 600, color: "secondary.main", backgroundColor: "#00EBFF10", borderRadius: 99 }}>{product.MKT_Category__c || "Option"}</Box> }
          </Stack>
        }
      </Stack>
      { <Typography fontSize="0.8rem" paragraph color="text.secondary">
        { product.MKT_ShortDescription__c }
        {product.MKT_ShortDescription__c && <Typography fontSize="0.6rem" component="span" color="secondary.main">(…)</Typography>}
      </Typography>
      }
    </Stack>
  </Entry>;
}
