const customShadows = function() {
  const s = { 0: "none" };

  for (let i = 1; i <= 25; i++) {
    s[i] = `
      0px ${Math.floor(i / 5)}px ${i}px -${Math.floor(i / 3)}px rgba(0,0,0,0.1),
      0px ${Math.round(i / 4)}px ${Math.round(i * 1.5)}px 0px rgba(0,0,0,0.1)
    `;
  }

  return s;
};

export default customShadows;
