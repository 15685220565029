import {
  Dialog,
  Typography,
  DialogTitle,
  DialogContent,
  IconButton,
  Box
}
  from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";

export default function CustomDialog({ title, subtitle, children, open, onClose, sx }) {
  return (
    <Dialog
      maxWidth="md"
      open={open}
      onClose={onClose}
      aria-labelledby="modal-title"
    >
      <DialogTitle component="div" sx={{ px: [2, 4, 6, 8], paddingTop: 6 }}>
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: "secondary.main"
          }}
        >
          <CloseIcon />
        </IconButton>
        <Box sx={{ px: 2 }}>
          <Typography align="center" variant="h2" component="h1" id="modal-title" mb={1}>{title}</Typography>
          { subtitle && <Typography align="center" variant="body1" component="h2" id="modal-title" mb={2}>{subtitle}</Typography>}
        </Box>
      </DialogTitle>
      <DialogContent sx={{ px: [2, 4, 6, 8] }}>
        <Box sx={{ pb: 2 }}>{children}</Box>
      </DialogContent>
    </Dialog>
  );
}
