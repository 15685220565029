import { styled, alpha } from "@mui/system";
import { Controller } from "react-hook-form";

import SliderUnstyled, { sliderUnstyledClasses } from "@mui/base/SliderUnstyled";

const blue = {
  100: "#DAECFF",
  200: "#99CCF3",
  400: "#3399FF",
  300: "#66B2FF",
  500: "#007FFF",
  600: "#0072E5",
  900: "#003A75",
};

const grey = {
  50: "#f6f8fa",
  100: "#eaeef2",
  200: "#d0d7de",
  300: "#afb8c1",
  400: "#8c959f",
  500: "#6e7781",
  600: "#57606a",
  700: "#424a53",
  800: "#32383f",
  900: "#24292f",
};

const StyledSlider = styled(SliderUnstyled)(
  ({ theme }) => `
  display: block;
  color: ${theme.palette.primary.main};
  height: 6px;
  margin: 1em;
  padding: 16px 0;
  position: relative;
  cursor: pointer;
  touch-action: none;
  -webkit-tap-highlight-color: transparent;

  &:hover {
    opacity: 1;
  }

  &.${sliderUnstyledClasses.disabled} {
    pointer-events: none;
    cursor: default;
    color: ${theme.palette.primary.light};
    opacity: 0.5;
  }

  & .${sliderUnstyledClasses.rail} {
    display: block;
    position: absolute;
    width: 100%;
    height: 4px;
    border-radius: 2px;
    background-color: ${theme.palette.secondary.main};
  }

  & .${sliderUnstyledClasses.track} {
    display: block;
    position: absolute;
    z-index: 1;
    margin: 0 -2px;
    height: 4px;
    border-radius: 2px;
    background-color: ${theme.palette.secondary.main};
  }

  & .${sliderUnstyledClasses.thumb} {
    position: absolute;
    z-index: 2;
    width: 16px;
    height: 16px;
    margin-left: -8px;
    margin-top: -6px;
    box-sizing: border-box;
    border-radius: 50%;
    outline: 0;
    border: 2px solid ${theme.palette.secondary.main};
    background-color: ${theme.palette.primary.main};

    :hover,
    &.${sliderUnstyledClasses.focusVisible} {
      box-shadow: 0 0 0 0.25rem ${alpha(
        theme.palette.mode === "light" ? blue[400] : blue[300],
        0.15,
      )};
    }

    &.${sliderUnstyledClasses.active} {
      box-shadow: 0 0 0 0.25rem ${alpha(
        theme.palette.mode === "light" ? blue[200] : blue[300],
        0.3,
      )};
    }
  }

  & .${sliderUnstyledClasses.mark} {
    position: absolute;
    width: 4px;
    height: 14px;
    border-radius: 2px;
    background-color: ${theme.palette.secondary.main};
    bottom: 12px;
    opacity: 0.7;
    transform: translateX(-50%);
  }

  & .${sliderUnstyledClasses.valueLabel} {
    display: none;
    font-family: inherit;
    font-size: 14px;
    position: relative;
    top: -1.6em;
    text-align: center;
    transform: translateX(-50%);
  }

  & .${sliderUnstyledClasses.markLabel} {
    font-family: inherit;
    font-size: 12px;
    position: absolute;
    bottom: 20px;
    transform: translateX(-50%);
    margin: 8px 0;
  }
`,
);

export default function TerminalSlider({ control }) {
  const marks = [
    {
      value: 740,
      label: "74cm",
    },
    {
      value: 1000,
      label: "1m",
    },
    {
      value: 1200,
      label: "1,2m",
    }
  ];

  return (
    <Controller
      render={
        ({ field }) => <StyledSlider
          defaultValue={20}
          valueLabelDisplay="auto"
          marks={marks}
          step={null}
          min={740}
          max={1200}
          {...field}
        />
      }
      control={control}
      name="terminal"
      defaultValue={""}
    />
  );
}
