import {
  Container
} from "@mui/material";
import { DefaultLayout } from "_layouts";

export default function Contracts() {
  return (
    <DefaultLayout>
      <Container>
        My contracts
      </Container>
    </DefaultLayout>
  );
}
