import {
  useTheme,
  Box,
  Chip
} from "@mui/material";
import { styled, experimental_sx as sx } from "@mui/material/styles";

import FinderCard from "../_FinderCard";

export default function Coverage({ updateData, data, currentStep }) {
  const theme = useTheme();

  const TagBox = styled(Box)(
    sx({
      p: 3,
      flex: "1 0 15rem",
      maxWidth: "50%",
      mb: 2,
      display: "flex",
      flexFlow: "row",
      flexWrap: "wrap",
      alignItems: "center",
      gap: 1,
      background: theme.palette.primary.grey2,
      borderRadius: theme.shape.borderRadius,
      border: `3px solid ${theme.palette.primary.grey2}`,
      transition: "all 0.16s ease-in-out"
    })
  );

  const chipValues = [
    ["North America Atlantic", "North America Atlantic"],
    ["South America", "South America"],
    ["Asia Pacific", "Asia Pacific"],
    ["Central America Carribean", "Central America Carribean"],
    ["Europe Mediteranean", "Europe Mediteranean"],
    ["Africa", "Africa"],
  ];

  return <>
    <TagBox>
      {chipValues.map(([v, l], index) => <Chip
        key={index}
        onClick={() => updateData(currentStep.name, v)}
        label={l}
        color={data[currentStep.name] === v ? "primary" : "default"}
      />
      )}
    </TagBox>
    <FinderCard
      value="Global"
      label="Global"
      active={data[currentStep.name] === "Global" ? "is-active" : ""}
      updateData={() => updateData(currentStep.name, "Global")}
    />
  </>;
}
