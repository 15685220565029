import React from "react";
import { Box } from "@mui/material";

import { Footer, Nav } from "_components";

const DefaultLayout = ({ children }) => <>
  <Nav/>
  <Box>
    {children}
  </Box>
  <Footer/>
</>;

export default DefaultLayout;
