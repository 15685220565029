import React, { lazy, Suspense, useMemo } from "react";
import ErrorBoundary from "./ErrorBoundary";
import getIconComponentName from "_helpers/getIconComponent";
import SVGIconEutelsat from "_assets/icons/components/SVGIconEutelsat";

export default function CustomIcon({ name, height, width, fill = "none", stroke = "currentColor", style = {} }) {
  const Icon = useMemo(() => lazy(() => import("_assets/icons/components/" + getIconComponentName(name))), [name]);

  return <ErrorBoundary fallback={<SVGIconEutelsat fill={fill} stroke={stroke} height={height} width={width} style={style}/>}>
    <Suspense>
      <Icon fill={fill} stroke={stroke} height={height} width={width} style={style}/>
    </Suspense>
  </ErrorBoundary>;
}
