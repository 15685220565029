import React from "react";
import ReactDOM from "react-dom/client";
import { unstable_HistoryRouter as HistoryRouter } from "react-router-dom";
import { RecoilRoot } from "recoil";
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";

import "./index.scss"; // eslint-disable-line import/no-unassigned-import
import "mapbox-gl/dist/mapbox-gl.css";

import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { history } from "./_helpers";
import startSentry from "./startSentry";
import theme from "_theme";

startSentry();

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <>
    <CssBaseline enableColorScheme />
    <HistoryRouter history={history}>
      <RecoilRoot>
        <GoogleReCaptchaProvider reCaptchaKey={process.env.REACT_APP_RECAPTCHA_KEY}>
          <ThemeProvider theme={theme}>
            <App />
          </ThemeProvider>
        </GoogleReCaptchaProvider>
      </RecoilRoot>
    </HistoryRouter>
  </>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
