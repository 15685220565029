import { useState, useEffect } from "react";
import {
  Box,
  TextField,
  Button,
  MenuItem,
  Link,
  FormControlLabel,
  Checkbox,
  Grid,
  Typography
}
  from "@mui/material";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosNewOutlinedIcon from "@mui/icons-material/ArrowBackIosNewOutlined";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";

import { CustomDialog, CustomStepper } from "_components";
import CustomIcon from "_components/CustomIcon";
import { useUserActions } from "_actions";
import theme from "_theme";

export default function Signup({ onClose, open, onToggle }) {
  const validationSchema = Yup.object().shape({

    // step1
    company: Yup.string().required("Company name is required"),
    company_website: Yup.string()
      .required("Company website is required")
      .matches(/^(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/, "This website adrress is invalid (ex: www.mywebsite.com)"),
    country: Yup.string().required("Country is required"),
    city: Yup.string().required("City is required"),
    company_type: Yup.string().required("Type of company is required"),

    // step2
    name: Yup.string().required("Name is required"),
    surname: Yup.string().required("Surname is required"),
    job_title: Yup.string().required("Job is required"),
    phonePrefix: Yup.string().required("Required"),
    phone: Yup.string().required("Phone number is required").matches(/^[0-9]+$/, "Must be only digits"),
    email_address: Yup.string().required("Email is required").email("Email is invalid"),

    // step3
    main_interest_lov: Yup.string().required("This information is required"),
    form_tc: Yup.boolean().oneOf([true], "You must accept terms")
  });

  const formOptions = { resolver: yupResolver(validationSchema) };
  const { register, handleSubmit, control, reset, trigger, formState: { errors, isSubmitting }, setError, getValues, clearErrors } = useForm(formOptions);
  const [activeStep, setActiveStep] = useState(0);
  const [dict, setDict] = useState(null);
  const [isVerifyingEmail, setIsVerifyingEmail] = useState(false);
  const [isSignUpAvailable, setIsSignUpAvailable] = useState(true);
  const userActions = useUserActions();

  useEffect(() => {
    userActions.getDict()
      .then(dict => setDict(dict))
      .catch(e => setIsSignUpAvailable(false));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function onSubmit(data) {
    return userActions.signup(data)
      .then(() => setActiveStep(3))
      .catch(error => {
        console.log(error);
      });
  }

  async function handleNext() {
    let isValid = false;

    switch (activeStep) {
      case 0:
        isValid = await trigger(["company", "company_website", "country", "city", "company_type"]);

        break;

      case 1:
        isValid = await trigger(["name", "surname", "job_title", "phonePrefix", "phone", "email_address"]);

        break;

      default:
        break;
    }

    if (isValid) {
      if (activeStep === 1) {
        setIsVerifyingEmail(true);

        userActions.verifyUser(getValues("email_address"))
          .then(resp => {
            if (resp.Success) {
              setError("email_address", { message: "This email already exists, please signin" });

              return;
            }

            clearErrors("email_address");
            setActiveStep(activeStep + 1);
          })
          .catch(console.log)
          .finally(() => setIsVerifyingEmail(false));
      } else {
        setActiveStep(activeStep + 1);
      }
    }
  }

  const steps = [{
    label: "My company", icon: <CustomIcon name="icon_mycompany" width="55%"/>
  }, {
    label: "My ID", icon: <CustomIcon name="icon_myidentity" width="55%"/>
  },
  {
    label: "My interest", icon: <CustomIcon name="icon_myinterest" width="55%"/>
  }
  ];

  function renderButtons() {
    if (activeStep > steps.length - 1) {
      return undefined;
    } else if (activeStep === steps.length - 1) {
      return (
        <Box sx={{ paddingTop: 2, display: "flex", justifyContent: "space-between", alignItems: "center" }}>
          <Button type="button" onClick={() => setActiveStep(activeStep - 1)} variant="outlined" disableElevation startIcon={<ArrowBackIosNewOutlinedIcon sx={{ mr: 2 }}/>} disabled={isSubmitting} ml={1}>
                Previous
          </Button>
          <Button type="submit" variant="contained" color="secondary" disableElevation endIcon={<ArrowForwardIosIcon />} disabled={isSubmitting} ml={1}>
            {isSubmitting && <span>loading..</span>}
                Validate
          </Button>
        </Box>
      );
    }

    return (
      <Box sx={{ paddingTop: 2, display: "flex", justifyContent: "space-between", alignItems: "center" }}>
        {(onToggle && activeStep === 0) && <Link onClick={() => onToggle() } variant="body2">You've already an account ?</Link>}
        { activeStep > 0 && <Button type="button" onClick={() => setActiveStep(activeStep - 1)} variant="outlined" disableElevation startIcon={<ArrowBackIosNewOutlinedIcon sx={{ mr: 2 }}/>} disabled={isSubmitting} ml={1}>
                Previous
        </Button>}
        <Button type="button" onClick={handleNext} variant="contained" color="secondary" disabled={isVerifyingEmail} disableElevation endIcon={<ArrowForwardIosIcon sx={{ ml: 4 }}/>} ml={1}>
          {isVerifyingEmail ? <span>loading...</span> : <span>Next</span>}
        </Button>
      </Box>
    );
  }

  function Step1() {
    return (
      <>
        <TextField
          fullWidth
          sx={{ marginBottom: 2 }}
          label="Company Name*"
          error={!!errors.company?.message}
          helperText={errors.company?.message}
          {...register("company")}
        />
        <TextField
          fullWidth
          sx={{ marginBottom: 2 }}
          label="Company Website*"
          error={!!errors.company_website?.message}
          helperText={errors.company_website?.message}
          {...register("company_website")}
        />
        <Controller
          render={
            ({ field }) => <TextField
              sx={{ marginBottom: 2 }}
              label="Country*"
              error={!!errors.country?.message}
              helperText={errors.country?.message}
              select
              fullWidth
              {...field}>
              {dict.country.map((c, index) => <MenuItem key={index} value={c}>{c}</MenuItem>)}

            </TextField>
          }
          control={control}
          name="country"
          defaultValue={""}
        />
        <TextField
          fullWidth
          sx={{ marginBottom: 2 }}
          label="City*"
          error={!!errors.city?.message}
          helperText={errors.city?.message}
          {...register("city")}
        />
        <Controller
          render={
            ({ field }) => <TextField
              sx={{ marginBottom: 2 }}
              error={!!errors.company_type?.message}
              helperText={errors.company_type?.message}
              label="Type of company*"
              select
              fullWidth
              {...field}>
              {dict.company_type.map((type, i) => <MenuItem key={i} value={type}>{type}</MenuItem>)}
            </TextField>
          }
          control={control}
          name="company_type"
          defaultValue={""}
        />
      </>
    );
  }

  function Step2() {
    return (
      <>
        <TextField
          fullWidth
          size= "small"
          sx={{ marginBottom: 2 }}
          label="Name*"
          error={!!errors.name?.message}
          helperText={errors.name?.message}
          {...register("name")}
        />
        <TextField
          fullWidth
          sx={{ marginBottom: 2 }}
          label="Surname*"
          error={!!errors.surname?.message}
          helperText={errors.surname?.message}
          {...register("surname")}
        />
        <TextField
          fullWidth
          sx={{ marginBottom: 2 }}
          label="Job Title*"
          error={!!errors.job_title?.message}
          helperText={errors.job_title?.message}
          {...register("job_title")}
        />
        <Grid container spacing={1} sx={{ marginBottom: 2 }}>
          <Grid item sx={{ width: "90px" }}>
            <TextField
              fullWidth
              label="Prefix*"
              error={!!errors.phonePrefix?.message}
              helperText={errors.phonePrefix?.message}
              placeholder="+33"
              {...register("phonePrefix")}
            /></Grid>
          <Grid item xs>
            <TextField
              fullWidth
              label="Phone number*"
              error={!!errors.phone?.message}
              helperText={errors.phone?.message}
              {...register("phone")}
            />
          </Grid>
        </Grid>
        <TextField
          fullWidth
          sx={{ marginBottom: 2 }}
          label="Email*"
          error={!!errors.email_address?.message}
          helperText={errors.email_address?.message}
          {...register("email_address")}
        />
      </>
    );
  }

  function Step3() {
    return (
      <>
        <Controller
          render={
            ({ field }) => <TextField
              sx={{ marginBottom: 2 }}
              error={!!errors.main_interest_lov?.message}
              helperText={errors.main_interest_lov?.message}
              label="What are you mainly interested in?*"
              select
              fullWidth
              {...field}>
              {dict.main_interest_lov.map((a, i) => <MenuItem key={i} value={a}>{a}</MenuItem>)}
            </TextField>
          }
          control={control}
          name="main_interest_lov"
          defaultValue={""}
        />
        <TextField
          sx={{ marginBottom: 2 }}
          multiline
          fullWidth
          placeholder="You have a personal question, or other need and specific informations"
          label="Special request :"
          rows={3}
          error={!!errors.special_request?.message}
          helperText={errors.special_request?.message}
          {...register("special_request")}
        />
        <FormControlLabel
          sx={{ fontStyle: "italic", color: theme.palette.text.grey }}
          control={
            <Controller
              control={control}
              name="form_tc"
              defaultValue={false}
              render={({ field: { onChange, value } }) => <Checkbox checked={value} onChange={onChange}/>
              }
            />
          }
          componentsProps={{ typography: { variant: "body2" } }}
          label={<>
          We collect and process your personal data in accordance with our <Link href="#">personal data protection policy</Link>*
          </>}
        />
        {!!errors.form_tc?.message && <Typography variant="error" sx={{ pl: 4 }}>{errors.form_tc?.message}</Typography>}
        <FormControlLabel
          sx={{ fontStyle: "italic", color: theme.palette.text.grey }}
          control={
            <Controller
              control={control}
              name="newsletter"
              defaultValue={false}
              render={({ field: { onChange, value } }) => <Checkbox checked={value} onChange={onChange}/>
              }
            />
          }
          componentsProps={{ typography: { variant: "body2" } }}
          label="I agree to receive sales and marketing information from Eutelsat via email and telephone"
        />
      </>
    );
  }

  function handleClose() {
    onClose();
    reset();
    setActiveStep(0);
  }

  if (!dict) {
    return null;
  }

  return (
    <CustomDialog
      open={open}
      onClose={handleClose}
      title={activeStep === 3 ? "It's almost done !" : "Create an account"}
      subtitle={activeStep === 3
        ? "Thank you for all this informations ; An email has been sent to you with a link to confirm you account"
        : "Create a BtoB account in just a few clicks"
      }
    >
      { isSignUpAvailable
        ? <>
          {activeStep < steps.length && <CustomStepper steps={steps} sx={{ marginBottom: 4 }} activeStep={activeStep} />}
          <form onSubmit={handleSubmit(onSubmit)}>
            {errors.apiError && <div>{errors.apiError?.message}</div>}
            { activeStep === 0 && <Step1/>}
            { activeStep === 1 && <Step2/> }
            { activeStep === 2 && <Step3/> }
            {renderButtons()}
          </form>
        </>
        : <Typography variant="error" sx={{ m: 4 }}>This service is temporarily unavailable</Typography>
      }

    </CustomDialog>
  );
}
