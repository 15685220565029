import {
  Box,
  Container,
  Stack,
  Typography,
  Grid,
  Button,
  Breadcrumbs,
  Link
} from "@mui/material";
import { Link as RouterLink, useParams } from "react-router-dom";
import Lottie from "react-lottie-player/dist/LottiePlayerLight";

import CatalogueEntry from "./_CatalogueEntry";
import SectionTitle from "./_SectionTitle";
import AnchorNavigation from "./_AnchorNavigation";
import DocumentCard from "./_DocumentCard";
import BenefitsList from "./_BenefitsList";
import carouselResponsiveLayout from "./carouselResponsiveLayout";
import { DefaultLayout } from "_layouts";
import { PageHero, Carousel } from "_components";
import routerImg from "_assets/images/HT2010-Router.png";
import animation1 from "_assets/animations/animation1";
import CustomIcon from "_components/CustomIcon";
import { useProductActions } from "_actions";

export default function Service() {
  const { product_code } = useParams();
  const productActions = useProductActions();
  const { product } = productActions.useEquipment(product_code);

  const links = product
    ? [
      ...product.MKT_KeyBenefits__c.length > 0 ? [{ label: "Key features", anchor: "keyBenefit" }] : [],

      ...product.MKT_Applications__c.length > 0 ? [{ label: "Use cases", anchor: "use-cases" }] : [],
      ...product.MKT_CompatibleServices__c.length > 0 ? [{ label: "Compatible services", anchor: "services" }] : [],
      ...product.MKT_Documents__c.length > 0 ? [{ label: "Documentation", anchor: "doc" }] : []
    ]
    : [];

  return (
    <DefaultLayout>
      <PageHero mb={4} />

      <Container maxWidth="xl">
        <Typography
          align="center"
          variant="h1"
          component="h1"
          fontSize={"2.625rem"}
          color="text.dark"
          mb={1}>
          Discover our catalogue
        </Typography>
        <Typography
          align="center"
          variant="subtitle1"
          color="text.secondary"
          mb={6}>
          Discover our Managed Services for Internet Connectivity
        </Typography>
      </Container>

      {!product
        ? <Box sx={{ display: "flex", justifyContent: "center", pb: 10 }}>
          <Lottie
            loop
            animationData={animation1}
            play
            style={{ width: 150, height: 150 }}
          />
        </Box>
        : <>
          <Container maxWidth="lg">
            <Breadcrumbs aria-label="breadcrumb" separator="›" mb={3}>
              <Link underline="none" component={RouterLink} to="/catalogue">
                <Typography variant="body2" sx={{ color: "text.secondary" }}>Products Catalogue</Typography>
              </Link>
              <Link underline="none" component={RouterLink} to="/catalogue?MKT_Category__c=Equipment">
                <Typography variant="body2" sx={{ color: "text.secondary" }}>Equipments</Typography>
              </Link>
              <Typography variant="body2">{product?.Name}</Typography>
            </Breadcrumbs>
            <Stack gap={4} direction="row" alignItems="flex-start" width="100%">
              <Box sx={{
                flex: "0 0 240px",
                width: 240,
                height: 240,
                backgroundColor: "primary.smoke",
                borderRadius: 2,
                justifyContent: "center",
                display: "flex",
                alignItems: "center"
              }}>
                <img src={routerImg} alt="" />

              </Box>
              <Stack width="100%">
                <Stack gap={2} direction="row">
                  <Box textTransform="uppercase" mb={1} flexBasis="100%">
                    <Typography variant="h2" color="text.primary" mb={0}>{product?.Name}</Typography>
                    <Typography variant="subtitle1" color="text.secondary" fontWeight={600}>{product.Family}</Typography>
                  </Box>
                  <Stack gap={2} direction="row" ml="auto" alignItems="flex-start">
                    <Box sx={{ px: 2, py: 1, fontSize: "0.875rem", fontWeight: 600, color: "secondary.main", backgroundColor: "primary.smoke", borderRadius: 99 }}>Equipment</Box>
                  </Stack>
                </Stack>
                <Typography paragraph fontSize="1.125rem" lineHeight="1.4">
                  {product.Description}
                </Typography>
              </Stack>
            </Stack>
          </Container>

          { links.length > 1 && <AnchorNavigation links={links}/> }

          {/* Key Features */}
          <Container maxWidth="lg">
            { product.MKT_KeyBenefits__c.length > 0
            && <Box id="keyBenefit" py={4}>
              <SectionTitle>Key features</SectionTitle>
              <BenefitsList benefits={product.MKT_KeyBenefits__c} />
            </Box>
            }

            {/* Uses Cases */}
            { product.MKT_Applications__c.length > 0
            && <Box id="keyBenefit" py={4}>
              <SectionTitle>Uses Cases</SectionTitle>
              <Container maxWidth="md">
                {product.MKT_Applications__c.map(application => <Box sx={{ mb: 4 }} key={application.Name}>
                  <Typography fontWeight="600" color="primary.dark" sx={{ mb: 0.5 }}>{application.Name}</Typography>
                  <Typography>{application.Description}</Typography>
                </Box>
                )}
              </Container>
            </Box>
            }

            {/* Comptatible services */}
            { product.MKT_CompatibleServices__c
            && <Box id="plans" py={4}>
              <SectionTitle>Comptatible services</SectionTitle>
              <Grid container>
                <Grid item lg={9}>
                  <Carousel cols={4} rows={1} gap={30} loop responsiveLayout={carouselResponsiveLayout}>
                    {product.MKT_CompatibleServices__c.map(service => <CatalogueEntry
                      sx={{ fontSize: "0.875rem", backgroundColor: "primary.grey2", height: "100%", boxShadow: "none", borderRadius: 3 }}
                      active={1}
                      display={"card"}
                      product={service}
                      key={service.ProductCode}
                    />)}
                  </Carousel>
                </Grid>
                <Grid item lg={3}>
                  <Stack alignItems="center" justifyContent="center" gap={2} height="100%">
                    <CustomIcon name="icon_productcatalogue" width="80" />
                    <Button
                      variant="contained"
                      color="secondary"
                      to="/catalogue"
                      component={RouterLink}>
                  Back to catalogue
                    </Button>
                  </Stack>
                </Grid>
              </Grid>
            </Box>
            }

            {/* Documentation */}
            { product.MKT_Documents__c.length > 0
            && <Box id="doc" py={4}>
              <SectionTitle>Documentation</SectionTitle>
              <Grid container spacing={4}>
                {product.MKT_Documents__c.map(doc => <Grid item xs={12} sm={6} md={6} lg={6} key={doc.Link}>
                  <DocumentCard sx={{ fontSize: "0.875rem", borderRadius: 2 }} display={"card"} doc={doc} />
                </Grid>)}
              </Grid>
            </Box>
            }

          </Container>
        </>}
    </DefaultLayout>
  );
}
