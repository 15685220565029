import {
  useTheme,
  Box,
  Stack,
  Typography,
  Link,
} from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import { styled, experimental_sx as sx } from "@mui/material/styles";
import routerImg from "_assets/images/HT2010-Router.png";

export default function EquipmentCard(props) {
  const { equipment } = props;
  const theme = useTheme();

  const entryStyle = sx({
    alignItems: "center",
    gap: 2,
    p: 2,
    borderRadius: 4,
    boxShadow: theme.shadows[12],
  });

  const Entry = styled(Stack)(entryStyle);

  return <Entry sx={{ fontSize: "1rem", ...props.sx }}>
    <Stack gap={2} direction="row" alignItems="center" width="100%" justifyContent="end">
      <Box sx={{ px: 2, py: 1, fontSize: "0.75em", fontWeight: 600, color: "secondary.main", backgroundColor: "#00EBFF10", borderRadius: 99 }}>Equipment</Box>
    </Stack>
    <Link component={RouterLink}
      to={`/equipments/${equipment.ProductCode}`}
      sx={{
        flex: "0 0 110px",
        width: 110,
        height: 110,
        justifyContent: "center",
        display: "flex",
        alignItems: "center"
      }}
    >
      <img src={routerImg} alt="" />
    </Link>
    <Stack textAlign="center">
      <Stack gap={2} direction="row" justifyContent="space-between">
        <Box textTransform="uppercase" mb={1} width="100%">
          <Link component={RouterLink} to={`/equipments/${equipment.ProductCode}`} underline="hover" sx={{ color: "text.primary" }} >
            <Typography variant="h3" fontSize="0.875rem" color="text.primary" mb={0}>{ equipment.Name }</Typography>
          </Link>
        </Box>
      </Stack>
      <Typography fontSize="0.75rem" fontWeight="600" paragraph color="text.secondary" style={{ whiteSpace: "pre-wrap" }}>
        { equipment.Family }
      </Typography>
    </Stack>
  </Entry>;
}
