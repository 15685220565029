import {
  Box,
  Typography,
} from "@mui/material";
import { experimental_sx as sx } from "@mui/material/styles";

const SectionTitle = function({ children }) {
  const style = sx({
    position: "relative",
    marginLeft: "2.5em",
    "&:before": {
      content: "''",
      position: "absolute",
      width: "2em",
      left: "-2.5em",
      bottom: "6px",
      borderBottom: "4px solid",
      borderColor: "secondary.main"
    }
  });

  return <Typography mb={3} variant="h3" component="h2" sx={style}>
    { children }
    <Box sx={{ position: "absolute", top: "-120px", width: "100%" }}></Box>
  </Typography>;
};

export default SectionTitle;
