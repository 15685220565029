import {
  Link,
  Typography,
  useTheme,
} from "@mui/material";

export default function NavLink({ href = "#", active = false, children = "lorem", sx }) {
  const theme = useTheme();

  const navLinkStyle = {
    display: "block",
    p: 1,
    py: 2,
    borderBottom: 1,
    borderColor: theme.palette.primary.light,
    textDecoration: "none",
    transition: "all 0.16s ease",
    fontWeight: active ? "900" : "500",
    background: active ? theme.palette.primary.smoke : "",
    color: active ? theme.palette.text.dark : theme.palette.primary.light,
    "&:hover": {
      background: theme.palette.primary.smoke,
      color: theme.palette.primary.dark,
    },
    ...sx
  };

  return <Link sx={navLinkStyle} href={href}>
    <Typography variant="h4" sx={{ fontSize: "1.125rem", fontWeight: "inherit" }}>
      { children }
    </Typography>
  </Link>;
}
