import {
  Container,
  TextField,
  MenuItem,
  Box,
  Typography,
  Button,
} from "@mui/material";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";

import { DefaultLayout } from "_layouts";
import { PageHero } from "_components";

export default function Contact() {
  const validationSchema = Yup.object().shape({
    comments: Yup.string().required("Comments is required"),
    subject: Yup.string().required("Subject is required"),
  });

  const formOptions = { resolver: yupResolver(validationSchema) };
  const { register, handleSubmit, control, formState: { errors, isSubmitting } } = useForm(formOptions);

  function onSubmit(data) {
    console.log(data);
  }

  return (
    <DefaultLayout>
      <PageHero mb={4} />
      <Container maxWidth="xl">
        <Typography
          align="center"
          variant="h1"
          component="h1"
          fontSize={"2.625rem"}
          color="text.dark"
          mb={1}>
      Contact Eutelsat
        </Typography>
        <Typography
          align="center"
          variant="body2"
          color="text.secondary"
          mb={6}>
      You want to connect to your Area Manager; please complete the form Below
        </Typography>
      </Container>
      <Container maxWidth="sm" sx={{ mb: 10 }}>
        <form onSubmit={handleSubmit(onSubmit)}>
          {errors.apiError && <div>{errors.apiError?.message}</div>}
          <Controller
            render={
              ({ field }) => <TextField
                sx={{ marginBottom: 2 }}
                error={!!errors.subject?.message}
                helperText={errors.subject?.message}
                label="Subject of this contact*"
                select
                fullWidth
                {...field}>
                {["Request an appointment", "Need informations about a product", "I 've got a problem on website", "I need a support"].map((a, i) => <MenuItem key={i} value={a}>{a}</MenuItem>)}
              </TextField>
            }
            control={control}
            name="subject"
            defaultValue={""}
          />
          <TextField
            sx={{ marginBottom: 6 }}
            multiline
            fullWidth
            placeholder="You have a personal question, or other need and specific informations"
            label="Comments"
            rows={5}
            error={!!errors.comments?.message}
            helperText={errors.comments?.message}
            {...register("comments")}
          />
          <Box sx={{ textAlign: "center" }}>
            <Button type="submit" variant="contained" color="secondary" disableElevation disabled={isSubmitting} ml={1}>
              {isSubmitting && <span>loading..</span>}
                  Validate
            </Button>
          </Box>
        </form>
      </Container>
    </DefaultLayout>
  );
}
