import { useRecoilValue } from "recoil";

import {
  useTheme,
  Typography,
  Box,
  Container,
  Stack,
  Avatar,
  Link,
} from "@mui/material";
import { styled, experimental_sx as sx } from "@mui/material/styles";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import { Link as RouterLink } from "react-router-dom";

import { authAtom } from "_state";
import { useUserActions } from "_actions";
import { useToggle } from "_helpers";
import logo from "_assets/images/eutelsat-logo.svg";
import CustomIcon from "_components/CustomIcon";

function Nav() {
  const theme = useTheme();
  const auth = useRecoilValue(authAtom);
  const userActions = useUserActions();
  const [isOpened, setIsOpened] = useToggle();

  if (!auth) {
    return null;
  }

  const style = {
    nav: {
      alignItems: "stretch",
      position: "sticky",
      top: 0,
      bgcolor: "background.paper",
      borderBottom: 1,
      borderColor: "grey.200",
      zIndex: 10
    },
    navLeft: {
      py: 1,
      paddingRight: [0, 2],
      alignItems: "center",
      flex: "1 0 auto"
    },
    navRight: {
      py: 1,
      px: [0, 2],
      borderLeft: 1,
      borderColor: ["transparent", "grey.200"],
      flex: ["0 1 auto", "0 1 400px"],
      alignItems: "center",
      justifyContent: "space-between",
      color: "brandColor"
    },
    img: { pr: 10 },
    burger: {
      color: "brandColor",
      cursor: "pointer"

    },
    burgerText: {
      fontWeight: "500",
      textTransform: "uppercase"
    },
    identity: {
      alignItems: "center",
      lineHeight: 1
    },
    avatar: { bgcolor: "brandColor", width: 35, height: 35, fontSize: "0.9rem" },
    navigationPanel: {
      position: "absolute",
      zIndex: "-1",
      width: "100%",
      background: theme.palette.background.default,
      p: 6,
      boxShadow: theme.shadows[1],
      transition: "all 0.2s ease-in-out",
      "&.hidden": {
        opacity: 0,
        transform: "translateY(-2rem)",
        clip: "rect(0 0 0 0)",
      }
    },
    accountLink: {
      color: "brandColor",
      textTransform: "uppercase",
      lineHeight: 1,
      fontWeight: 900,
      cursor: "pointer",
      textDecoration: "none",
      "&:hover": {
        textDecoration: "underline"
      }
    },
    signOutLink: {
      color: "text.secondary",
      fontWeight: 500,
      cursor: "pointer",
      textDecoration: "none",
      "&:hover": {
        textDecoration: "underline"
      }
    }
  };

  const NavLink = styled(Link)(
    sx({
      display: "block",
      p: 2,
      borderTop: 1,
      borderColor: theme.palette.primary.smokeBorder,
      textDecoration: "none",
      textTransform: "uppercase",
      fontSize: "1rem",
      fontWeight: "600",
      transition: "all 0.16s ease",
      "&:hover": {
        pl: 4,
        background: theme.palette.primary.smoke,
        color: theme.palette.secondary.main,
      }
    })
  );

  return (
    <Box sx={style.nav}>
      <Container maxWidth="xl">
        <Stack direction={["column", "row"]}>
          <Stack direction="row" sx={style.navLeft}>
            <Box sx={style.img}>
              <RouterLink to="/"><img width="110" src={logo} alt="logo eutelsat"/></RouterLink>
            </Box>
            <Stack spacing={1} direction="row" alignItems="center" sx={style.burger} onClick={setIsOpened}>
              {isOpened
                ? <CloseIcon color="inherit"/>
                : <MenuIcon color="inherit"/>
              }
              <Typography variant="body4" sx={style.burgerText}>Menu</Typography>
            </Stack>
          </Stack>
          <Stack direction="row" sx={style.navRight}>
            <Stack direction="row" sx={style.identity}>
              <Avatar sx={style.avatar}>OP</Avatar>
              <Stack ml={1} gap={0.25}>
                <Link
                  component={RouterLink}
                  variant="body4"
                  sx={style.accountLink}
                  to="/profile">
                  My Account
                </Link>
                <Link
                  variant="body4"
                  sx={style.signOutLink}
                  onClick={userActions.logout}>
                  Sign out
                </Link>
              </Stack>
            </Stack>
            <Link component={RouterLink} to="/contact">
              <CustomIcon name="icon_contact" width="30" height="30" />
            </Link>
          </Stack>
        </Stack>
      </Container>
      <Box sx={style.navigationPanel} className={isOpened ? "" : "hidden"}>
        <Box sx={{ maxWidth: "18rem" }}>
          <NavLink to="/catalogue-index" component={RouterLink}>Product Catalogue</NavLink>
          <NavLink to="/coverage" component={RouterLink}>Coverage</NavLink>
          <NavLink to="/contact" component={RouterLink}>Contact Eutelsat</NavLink>
          {/* <NavLink to="/services" component={RouterLink}>My services</NavLink> */}
          {/* <NavLink>Support</NavLink> */}
        </Box>
      </Box>
    </Box>
  );
}

export default Nav;
