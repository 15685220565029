import {
  useTheme,
  Container,
  Grid,
  Box,
  Paper,
  Typography,
  Button,
  Link
} from "@mui/material";
import { styled, experimental_sx as sx } from "@mui/material/styles";

import FinderPaper from "./_FinderPaper";
import HeroUnit from "./_HeroUnit";
import { DefaultLayout } from "_layouts";
import { Heading } from "_components";
import CustomIcon from "_components/CustomIcon";
import homeBackground0 from "_assets/images/home_bottom_0.jpg";
import homeBackground1 from "_assets/images/home_bottom_1.jpg";

function Home() {
  const theme = useTheme();

  const ServicesPaper = styled(Paper)(
    sx({
      textAlign: "center",
      p: 4
    })
  );

  const ServicesBox = styled(Box)(
    sx({
      px: [2, 4, 6, 10],
      pt: [3, 8, 10, 15]
    })
  );

  return (
    <DefaultLayout>
      <HeroUnit />
      <Container maxWidth="xl" sx={{ mb: 8 }}>
        <FinderPaper/>
      </Container>
      <Heading
        title="My Contrats"
        darkMode={false}
        subtitle="Choose a service below to connect to your portal and manage your contract"
      />
      <Container maxWidth="xl" sx={{ mb: 8 }}>
        <Grid container spacing={6}>
          <Grid item xs={12} md={6}>
            <ServicesBox sx={{ background: `${theme.palette.primary.dark} url(${homeBackground0}) center / cover` }}>
              <ServicesPaper elevation={0} square>
                <CustomIcon name="icon_video" width="70" stroke={theme.palette.secondary.main} />
                <Typography variant="h3" component="h3" mt={2} mb={1}>Video & Core MHZ Portal</Typography>
                <Typography sx={{ px: [0, 2, 4, 6] }} variant="subtitle2" paragraph maxWidth="sm">
                If you already have access to Eutelsat’s online tools, please use your usual login details to connect.
                </Typography>
                <Button variant="contained" color="secondary" size="sm">Contact Eutelsat</Button>
              </ServicesPaper>
            </ServicesBox>
          </Grid>
          <Grid item xs={12} md={6}>
            <ServicesBox sx={{ background: `${theme.palette.primary.dark} url(${homeBackground1}) center / cover` }}>
              <ServicesPaper elevation={0} square>
                <CustomIcon name="icon_siteextranet" width="70" stroke={theme.palette.secondary.main} />
                <Typography variant="h3" component="h3" mt={2} mb={1}>Connectivity portal</Typography>
                <Typography sx={{ px: [0, 2, 4, 6] }} variant="subtitle2" paragraph maxWidth="sm">
                If you already have access to Konnect or ADVANCE online tools, please use your usual login details to connect.
                </Typography>
                <Link underline="none" href="https://skylogicb2cpartnerdemo.b2clogin.com/skylogicb2cpartnerdemo.onmicrosoft.com/oauth2/v2.0/authorize?p=B2C_1A_FORCEPASSWORDRESET_SIGNUP_SIGNIN&client_id=34b5b438-d6eb-4d4a-9279-513680fa26fa&nonce=defaultNonce&redirect_uri=https%3A%2F%2Feutelsat--demo.sandbox.my.site.com%2Fpartnerportal%2Fservices%2Fauth%2Fsso%2Fbip_sfc_b2c_partner_demo&scope=openid%20https%3A%2F%2Fskylogicb2cpartnerdemo.onmicrosoft.com%2Fsfc%2Fread%20https%3A%2F%2Fskylogicb2cpartnerdemo.onmicrosoft.com%2Fsfc%2Fuser_impersonation&response_type=id_token%20token&prompt=login"><Button variant="contained" color="secondary" size="sm">Access partner portal</Button></Link>
              </ServicesPaper>
            </ServicesBox>
          </Grid>
        </Grid>
      </Container>
    </DefaultLayout>
  );
}

export default Home;
