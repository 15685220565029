import {
  Box,
} from "@mui/material";
import { useState, useRef, useLayoutEffect } from "react";
import { experimental_sx as sx } from "@mui/material/styles";
import { ChevronLeft, ChevronRight } from "@mui/icons-material";
import shadows from "@mui/material/styles/shadows";

export default function Carousel({ children, rows = 2 }) {
  const [needPrevScrollButtons, setNeedPrevScrollButtons] = useState(false);
  const [needNextScrollButtons, setNeedNextScrollButtons] = useState(false);
  const scrollable = useRef();

  function setNeedScrollButtons() {
    window.setTimeout(() => {
      if (!scrollable.current) {
        return false;
      }

      setNeedPrevScrollButtons(scrollable.current.scrollWidth > scrollable.current.offsetWidth && scrollable.current.scrollLeft !== 0);
      setNeedNextScrollButtons(scrollable.current.scrollWidth > scrollable.current.offsetWidth && scrollable.current.scrollLeft !== scrollable.current.scrollWidth - scrollable.current.offsetWidth);
    }, 0);
  }

  useLayoutEffect(() => {
    setNeedScrollButtons();

    scrollable.current.addEventListener("scroll", () => {
      setNeedScrollButtons();
    });
  }, []);

  function scrollRight(e) {
    e.preventDefault();

    scrollable.current.scroll({
      left: scrollable.current.scrollLeft + scrollable.current.offsetWidth * 0.5,
      behavior: "smooth"
    });

    setNeedScrollButtons();
  }

  function scrollLeft(e) {
    e.preventDefault();

    scrollable.current.scroll({
      left: scrollable.current.scrollLeft - scrollable.current.offsetWidth * 0.5,
      behavior: "smooth"
    });

    setNeedScrollButtons();
  }

  const CarouselStyle = sx({
    "overflow": "auto",
    "display": "grid",
    "gridDirection": "row",
    "gridAutoFlow": "column",
    "gridAutoColumns": "minmax(12rem, 25%)",
    "gridTemplateRows": `repeat(${rows}, 1fr)`,
    "scrollbarWidth": "none",
    "gap": "20px",

    "&::-webkit-scrollbar": {
      "display": "none",
    },

    ".hidden": {
      opacity: 0,
      pointerEvents: "none",
    },

    ".prev, .next, .gradient": {
      transition: "all 0.16s ease-out",
    },

    ".prev, .next": {
      "cursor": "pointer",
      "position": "absolute",
      "zIndex": "6",
      "top": "46%",
      "width": "3em",
      "height": "3em",
      "display": "flex",
      "alignItems": "center",
      "justifyContent": "center",
      "color": "primary.main",
      "backgroundColor": "#fff",
      "boxShadow": shadows[1],
      "border": "0",
      "borderRadius": "50%",
      "&:hover": {
        "color": "#fff",
        "backgroundColor": "primary.main",
      },
    },
    ".gradient": {
      content: "''",
      pointerEvents: "none",
      position: "absolute",
      top: 0,
      bottom: 0,
      width: "3em",
      background: "linear-gradient(to right, #fff, transparent)",
      "&.left": {
        left: 0,
      },
      "&.right": {
        right: 0,
        transform: "rotate(180deg)",
      }
    },
    ".prev": {
      "left": "-1.5em",
      "&:hover": {
        transform: "translateX(-10%)",
      }
    },
    ".next": {
      "right": "-1.5em",
      "&:hover": {
        transform: "translateX(10%)",
      }
    },
  });

  return <Box sx={{ position: "relative" }}>
    <Box sx={CarouselStyle} ref={scrollable}>
      <div className={`${!needPrevScrollButtons && "hidden"} gradient left`}></div>
      <div className={`${!needPrevScrollButtons && "hidden"} prev`} onClick={(e => scrollLeft(e))} ><ChevronLeft/></div>
      { children }
      <div className={`${!needNextScrollButtons && "hidden"} gradient right`}></div>
      <div className={`${!needNextScrollButtons && "hidden"} next`} onClick={(e => scrollRight(e))} ><ChevronRight /></div>
    </Box>
  </Box>;
}
