import {
  useTheme,
  Container,
  Box,
  Typography
} from "@mui/material";

import whiteLogoUrl from "_assets/images/eutelsat-logo-white.svg";
import coverUrl from "_assets/images/landing-cover.jpg";

function HeroUnit() {
  const theme = useTheme();

  const heroUnitSx = {
    paddingBottom: "28vh",
    background: `${theme.palette.primary.main}CC`,
    color: theme.palette.primary.smoke
  };

  return (
    <Box sx={{ background: `${theme.palette.primary.main} url('${coverUrl}') center / cover` }}>
      <Box sx={heroUnitSx}>
        <Container maxWidth="xl" sx={{ position: "relative" }}>
          <Box component="nav" sx={{ py: 4 }}>
            <img src={whiteLogoUrl} alt="eutelsat logo" width={180} />
          </Box>
          <Container align="center" maxWidth={"lg"} padding={"15vh"}>
            <Typography variant="h1" component="h1" mb={2}>Welcome !</Typography>
            <Typography paragraph variant="subtitle1">
            Designed for our B2B Customers, the Eutelsat Portal provides you with all the support you need to work more closely with Eutelsat. Please use this portal to discover Eutelsat products and services, manage your contract and access your service portals amongst other features.
            </Typography>
          </Container>
        </Container>
      </Box>
    </Box>
  );
}

export default HeroUnit;
