const typography = {
  fontFamily: "\"Poppins\", \"Helvetica\", \"Arial\", \"sans-serif\"",
  fontSize: 16,
  body1: {
    fontSize: "1rem",
    lineHeight: 1.2
  },
  body2: {
    fontSize: "0.875rem",
    lineHeight: 1.2
  },
  body3: {
    fontSize: "0.75rem",
    lineHeight: 1.2
  },
  body4: {
    fontSize: "0.625rem",
    lineHeight: 1.2
  },
  error: {
    fontSize: "0.85rem",
    lineHeight: 1.2,
    color: "#d32f2f"
  },
  subtitle1: {
    fontSize: "1.25rem",
    fontWeight: 500,
    lineHeight: 1.3
  },
  subtitle2: {
    fontWeight: 400,
    lineHeight: 1.3
  },
  button: {
    fontSize: "0.75rem",
    fontWeight: 700
  },
  h1: { fontWeight: "700", fontSize: "3.125rem" },
  h2: { fontWeight: "700", fontSize: "2.25rem" },
  h3: { fontWeight: "700", fontSize: "1.5rem" },
  h4: { fontWeight: "700", fontSize: "1.25rem" },
  h5: { fontWeight: "700", fontSize: "1rem" },
  h6: { fontWeight: "700", fontSize: "0.875rem" }
};

export default typography;
