import { Routes, Route } from "react-router-dom";
import { useRecoilValue } from "recoil";
import CssBaseline from "@mui/material/CssBaseline";
// eslint-disable-next-line import/no-unresolved
import { ClickToComponent } from "click-to-react-component"; // Development tool
import { useEffect } from "react";
import { SWRConfig } from "swr";

import Profile from "profile/Profile";
import Services from "services/Services";
import Contracts from "contracts/Contracts";
import SolutionFinder from "solution-finder/SolutionFinder";
import CatalogueIndex from "catalogue/CatalogueIndex";
import Catalogue from "catalogue/Catalogue";
import Service from "catalogue/Service";
import Equipment from "catalogue/Equipment";
import Home from "home/Home";
import Landing from "landing/Landing";
import NotFound from "redirect/NotFound";
import ProspectCreated from "redirect/ProspectCreated";
import { authAtom } from "_state";
import { ProtectedRoute } from "_components";
import { useUserActions } from "_actions";
import Coverage from "coverage/Coverage";
import Contact from "contact/Contact";
import { useFetchWrapper } from "_helpers";

function App() {
  const auth = useRecoilValue(authAtom);
  const userActions = useUserActions();
  const fetchWrapper = useFetchWrapper();

  useEffect(() => {
    userActions.getCurrentUser();
  }, []);

  return (
    <SWRConfig
      value={{
        revalidateOnFocus: false,
        fetcher: fetchWrapper.get
      }}
    >
      <ClickToComponent />
      <CssBaseline />
      <div>
        <Routes>
          <Route index element={<Landing />} />
          <Route path="landing" element={<Landing />} />
          <Route path="prospect-created" element={<ProspectCreated />} />
          <Route
            path="home"
            element={
              <ProtectedRoute isAllowed={!!auth}>
                <Home />
              </ProtectedRoute>
            }
          />
          <Route
            path="profile"
            element={
              <ProtectedRoute isAllowed={!!auth}>
                <Profile />
              </ProtectedRoute>
            }
          />
          <Route
            path="services"
            element={
              <ProtectedRoute isAllowed={!!auth}>
                <Services />
              </ProtectedRoute>
            }
          />
          <Route
            path="contracts"
            element={
              <ProtectedRoute isAllowed={!!auth}>
                <Contracts />
              </ProtectedRoute>
            }
          />
          <Route
            path="solution-finder"
            element={
              <ProtectedRoute isAllowed={!!auth}>
                <SolutionFinder />
              </ProtectedRoute>
            }
          />
          <Route
            path="catalogue-index"
            element={
              <ProtectedRoute isAllowed={!!auth}>
                <CatalogueIndex />
              </ProtectedRoute>
            }
          />
          <Route
            path="catalogue"
            element={
              <ProtectedRoute isAllowed={!!auth}>
                <Catalogue />
              </ProtectedRoute>
            }
          />
          <Route
            path="services/:product_code"
            element={
              <ProtectedRoute isAllowed={!!auth}>
                <Service />
              </ProtectedRoute>
            }
          />
          <Route
            path="equipments/:product_code"
            element={
              <ProtectedRoute isAllowed={!!auth}>
                <Equipment />
              </ProtectedRoute>
            }
          />
          <Route
            path="coverage"
            element={
              <ProtectedRoute isAllowed={!!auth}>
                <Coverage />
              </ProtectedRoute>
            }
          />
          <Route
            path="contact"
            element={
              <ProtectedRoute isAllowed={!!auth}>
                <Contact />
              </ProtectedRoute>
            }
          />
          <Route path="*" element={<NotFound/>} />
        </Routes>
      </div>
    </SWRConfig>
  );
}

export default App;
