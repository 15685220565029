import { useState, useMemo } from "react";

import useQueryAsState from "_helpers/use-querystate";
import finderJson from "_helpers/solution-finder";

export default function useFinder() {
  const [data, setData] = useQueryAsState({});

  const initialStep = finderJson.steps.reduce((acc, s, index) => {
    if (s.name in data) {
      acc = index;
    }

    return acc;
  }, 0);

  const [currentStepIndex, setCurrentStepIndex] = useState(initialStep);

  const [currentStep, currentGroup, currentGroupIndex] = useMemo(() => {
    const step = finderJson.steps[currentStepIndex];
    const group = finderJson.groups.find(g => g.step_ids.includes(step.id));
    const groupIndex = finderJson.groups.findIndex(g => g.id === group.id);

    return [step, group, groupIndex];
  }, [currentStepIndex]);

  // Get paths with valid parent steps
  // retourne les chemins qui correspondent aux valeurs dans data en excluant les clé de datas des étapes courante et prochaines
  const availablePaths =
    finderJson.paths
      .filter(p => Object.keys(data)

        // keep only step_name from data which is previous current step)
        .filter(step_name => finderJson.steps.findIndex(s => s.name === step_name) < finderJson.steps.findIndex(s => s.name === currentStep.name))

        // keep paths with corresponding value by step
        .every(e => p[e] === parseInt(data[e])) //
      );

  // Same as availablePaths but with current step consideration
  const nextPaths =
    finderJson.paths
      .filter(p => Object.keys(data)

        // keep only step_name from data which is previous current step including current)
        .filter(step_name => finderJson.steps.findIndex(s => s.name === step_name) <= finderJson.steps.findIndex(s => s.name === currentStep.name))

        // keep paths with corresponding value by step
        .every(e => p[e] === parseInt(data[e])) //
      );

  // of the following steps retrieves the next index of the first one that has non-null values among the next valid paths
  const nextStepIndex =
    finderJson.steps
      .findIndex((s, index) => index > currentStepIndex && nextPaths.map(p => p[s.name]).some(v => v !== null));

  // of the previous steps retrieves the index of the last one that has non-null values among the availables paths
  const prevStepIndex =
      finderJson.steps
        .findLastIndex((s, index) => index < currentStepIndex && availablePaths.map(p => p[s.name]).some(v => v !== null));

  const isLastStep = useMemo(() => nextStepIndex === -1, [nextStepIndex]);

  const availableValues =
    [...new Set(availablePaths.map(p => p[currentStep.name]))]
      .map(value_id => finderJson.values.find(v => v.id === value_id));

  function handleNext() {
    setCurrentStepIndex(nextStepIndex);
  }

  function handlePrev() {
    setCurrentStepIndex(prevStepIndex);
  }

  function updateData(field, value_id) {
    // if value_id doesn't change return
    if (parseInt(data[currentStep.name]) === value_id) {
      return;
    }

    // set current step value and reset next steps values
    const cleanedData = Object.keys(data).reduce((acc, key) => {
      if (finderJson.steps.findIndex(s => s.name === key) < currentStepIndex) {
        acc[key] = data[key];
      }

      return acc;
    }, {});

    setData({ ...cleanedData, [field]: value_id });
  }

  function handleSubmit(callback) {
    return callback(data);
  }

  const serializedData = Object.keys(data).reduce((acc, current) => {
    const value = finderJson.values.find(v => v.id === parseInt(data[current]))?.value;

    acc[current] = value ?? data[current];

    return acc;
  }, {});

  return {
    currentGroupIndex,
    currentGroup,
    currentStepIndex,
    currentStep,
    availableValues,
    isLastStep,
    handleNext,
    handlePrev,
    data,
    serializedData,
    updateData,
    handleSubmit
  };
}
