import {
  Box,
  Button,
  Container,
  Typography,
  Stack,
  useTheme,
} from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import { styled, experimental_sx as sx } from "@mui/material/styles";
import { useRecoilValue } from "recoil";

import { authAtom } from "_state";
import bannerBackgroundUrl from "_assets/images/hero-banner.jpg";

export default function PageHero(props) {
  const theme = useTheme();
  const auth = useRecoilValue(authAtom);

  const HeroBox = styled(Box)(
    sx({
      py: 8,
      color: "#fff",
      boxShadow: theme.shadows[12],
      borderBottom: 2,
      background: `${theme.palette.primary.main} url(${bannerBackgroundUrl}) center / cover`
    })
  );

  return (
    <HeroBox {...props}>
      <Container maxWidth="xl">
        <Stack direction="row" spacing={8} alignItems="center">
          <Typography variant="h3" mr="auto" sx={{ fontWeight: "normal", fontSize: "1.625rem" }}>
            Welcome a board&nbsp;:<br />
            <strong>{auth.name} {auth.surname}</strong>
          </Typography>

          <Button component={RouterLink} to="/contact" variant="contained" color="secondary" sx={{ marginBottom: 2 }}>Contact Eutelsat</Button>

          {/* <Typography variant="body3" mb={2}>
            Your Area manager is<br />{"Luke Skywalker"}<br />
            <Link variant="body4" sx={{ color: theme.palette.secondary.main, fontWeight: 500 }}>{"lSkywalker@rebels.com"}</Link>
          </Typography> */}

        </Stack>
      </Container>
    </HeroBox>
  );
}
