import {
  Typography,
  Box,
  Button
} from "@mui/material";
import { useState, useEffect } from "react";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { Link as RouterLink, useSearchParams } from "react-router-dom";
import {
  useGoogleReCaptcha
} from "react-google-recaptcha-v3";

import { RedirectLayout } from "_layouts";
import { useUserActions } from "_actions";

function errorMessage(errorType) {
  let message = "";

  switch (errorType) {
    case "unknown_user":
      message = "Unknown user";

      break;

    case "expired_token":
      message = "Confirmation link has expired";

      break;

    case "unconfirmed_email_address":
      message = "You must confirm your email before access the portal";

      break;

    case "resend_email_fail":
      message = "Email resent fails";

      break;

    case "already_confirmed":
      message = "Your email has been already confirmed, please login";

      break;

    default:
      message = "Something wrong happens";

      break;
  }

  return message;
}

export default function ProspectCreated() {
  const userActions = useUserActions();
  const [searchParams] = useSearchParams();
  const [isLoading, setIsLoading] = useState(true);
  const [hasResend, setHasResend] = useState(false);
  const [errorType, setErrorType] = useState(searchParams.get("error"));
  const { executeRecaptcha } = useGoogleReCaptcha();

  function resendConfirmation() {
    userActions.resendConfirmation(searchParams.get("uuid"))
      .then(() => setHasResend(true))
      .catch(() => setErrorType("resend_email_fail"));
  }

  useEffect(() => {
    if (!executeRecaptcha) {
      return;
    }

    if (errorType) {
      setIsLoading(false);

      return;
    }

    if (searchParams.get("uuid")) {
      userActions.getUserByUID(searchParams.get("uuid"))
        .then(resp => userActions.createProspect(resp.user), e => setErrorType("unknown_user"))
        .catch(e => {
          setErrorType("mule_error");
          userActions.postMuleStatus(searchParams.get("uuid"), e);
        })
        .finally(() => setIsLoading(false));
    } else {
      setErrorType("invalid_url");
      setIsLoading(false);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [executeRecaptcha]);

  if (isLoading) {
    return (
      <RedirectLayout>
        <div>Loading...</div>
      </RedirectLayout>
    );
  }

  if (errorType) {
    return (
      <RedirectLayout>
        <Typography variant="body" sx={{ mb: 6, display: "block" }}>
          { errorMessage(errorType) }
          { errorType === "unconfirmed_email_address" && !hasResend && <Button sx={{ mt: 6 }} onClick={resendConfirmation}>Resend confirmation email</Button> }
          { errorType === "unconfirmed_email_address" && hasResend && <Typography variant="body" sx={{ mb: 6, display: "block" }}>Email sent !</Typography>}
        </Typography>
        <Button component={RouterLink} to="/" variant="contained" color="secondary">Back to Home</Button>
      </RedirectLayout>
    );
  }

  return (
    <RedirectLayout>
      <Typography variant="h3" sx={{ mb: 2 }}>Your're almost there!</Typography>
      <Typography variant="body" sx={{ mb: 6, display: "block" }}>
              Thank you for completing the form.<br/> Our Sales team will now check your registration details and will send you an email for you to create a password and login to your new account.
      </Typography>
      <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", mb: 6 }}>
        <InfoOutlinedIcon sx={{ mr: 1 }} fontSize="small"/>
        <Typography variant="body2">Please check your spam if you don't receive an email within 48hrs</Typography>
      </Box>
      <Button component={RouterLink} to="/" variant="contained" color="secondary">Back to Home</Button>
    </RedirectLayout>
  );
}
